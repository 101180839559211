import { InvoiceType } from "./type";

const initialAuthState = {
  invoices: null,
  invoice: null,
  isLoading: false,
  statusCode: null,
  invoiceCount: null,
  isCountLoader: false,
  billCount: null,
  isSelfAdminLoader: false,
  selfAdminStatementList: [],
  isPdfLoading: false,
  statementPdf: null
};

export const reducer = (state = initialAuthState, action) => {
  switch (action.type) {
    case InvoiceType.GET_INVOICES_REQUEST:
      return { ...state, isLoading: true };
    case InvoiceType.GET_INVOICES_SUCCESS:
      return { ...state, isLoading: false, invoices: action.payload };
    case InvoiceType.GET_INVOICES_FAIL:
      return { ...state, isLoading: false, error: action.error };
    case InvoiceType.GET_INVOICE_REQUEST:
      return { ...state, isLoading: true, statusCode: null };
    case InvoiceType.GET_INVOICE_SUCCESS:
      return { ...state, isLoading: false, invoice: action.payload };
    case InvoiceType.GET_INVOICE_FAIL:
      return { ...state, isLoading: false, error: action.error, statusCode: action.statusCode };
    case InvoiceType.SET_INVOICE_REQUEST:
      return { ...state, isLoading: true };
    case InvoiceType.SET_INVOICE_SUCCESS:
      return { ...state, isLoading: false };
    case InvoiceType.SET_INVOICE_FAIL:
      return { ...state, isLoading: false };
    case InvoiceType.INVOICE_COUNT_REQUEST:
      return { ...state, isCountLoader: true };
    case InvoiceType.INVOICE_COUNT_SUCCESS:
      return { ...state, isCountLoader: false, invoiceCount: action.payload };
    case InvoiceType.INVOICE_COUNT_FAIL:
      return { ...state, isCountLoader: false };
    case InvoiceType.BILL_COUNT_REQUEST:
      return { ...state, isCountLoader: true };
    case InvoiceType.BILL_COUNT_SUCCESS:
      return { ...state, isCountLoader: false, billCount: action.payload };
    case InvoiceType.BILL_COUNT_FAIL:
      return { ...state, isCountLoader: false };
    case InvoiceType.SELF_ADMIN_LIST_REQUEST:
      return { ...state, isSelfAdminLoader: true };
    case InvoiceType.SELF_ADMIN_LIST_SUCCESS:
      return { ...state, isSelfAdminLoader: false, selfAdminStatementList: action.payload };
    case InvoiceType.SELF_ADMIN_LIST_FAIL:
      return { ...state, isSelfAdminLoader: false };
    case InvoiceType.PDF_REQUEST:
      return { ...state, isPdfLoading: true };
    case InvoiceType.PDF_SUCCESS:
      return { ...state, isPdfLoading: false, statementPdf: action.payload };
    case InvoiceType.PDF_FAIL:
      return { ...state, isPdfLoading: false };
    default:
      return state;
  }
};
