import { toast } from "react-toastify";
import { API_END_POINTS } from "../../../utils/config";
import JsonInterceptor, { axiosForMultipart, axiosForMultipartLocationAPI } from "../../../utils/JsonInterceptor";
import { AuthType } from "./type";
import axios from "axios";
import { errorMessage, uploadProgress } from "../../../utils/custom";

export const signin = (body) => async (dispatch) => {
  dispatch({ type: AuthType.USER_SIGNIN_REQUEST });
  try {
    let apiEndpoint = `${API_END_POINTS.login}`;
    const { data } = await JsonInterceptor.post(`${apiEndpoint}`, body);
    localStorage.setItem("spinzyPlayAdminUSA", JSON.stringify(data.data));
    return dispatch({ type: AuthType.USER_SIGNIN_SUCCESS, payload: data });
  } catch (error) {
    return dispatch({
      type: AuthType.USER_SIGNIN_FAIL,
      payload: error && error?.data && error?.data
    });
  }
};

export const logout = () => async (dispatch) => {
  try {
    dispatch({
      type: AuthType.LOG_OUT
    });
  } catch (error) {
    return error;
  }
};

//authCheck
export const authCheck = () => async (dispatch) => {
  try {
    dispatch({ type: AuthType.AUTH_CHEK_REQUEST });
    let apiEndpoint = `${API_END_POINTS.authCheck}`;
    const { data } = await JsonInterceptor.get(`${apiEndpoint}`);
    await localStorage.setItem("spinzyPlayAdminUSA", JSON.stringify(data.data));
    return dispatch({
      type: AuthType.AUTH_CHEK_SUCCESS,
      payload: data
    });
  } catch (error) {
    const message = error && error.data?.message ? error.data?.message : errorMessage();
    toast.error(message);
    return dispatch({ type: AuthType.AUTH_CHEK_FAIL, error: message });
  }
};

//authRefreshCaptcha
export const authRefreshCaptcha = (body) => async (dispatch) => {
  try {
    dispatch({ type: AuthType.REFRESH_CAPTCHA_REQUEST });
    let apiEndpoint = `${API_END_POINTS.authRefreshCaptcha}`;
    const { data } = await JsonInterceptor.post(`${apiEndpoint}`, body);
    return dispatch({
      type: AuthType.REFRESH_CAPTCHA_SUCCESS,
      payload: data
    });
  } catch (error) {
    const message = error && error.data?.message ? error.data?.message : errorMessage();
    toast.error(message);
    return dispatch({ type: AuthType.REFRESH_CAPTCHA_FAIL, error: message });
  }
};

//authCheckCaptcha
export const authCheckCaptcha = (body) => async (dispatch) => {
  try {
    dispatch({ type: AuthType.CHEK_CAPTCHA_REQUEST });
    let apiEndpoint = `${API_END_POINTS.authCheckCaptcha}`;
    const { data } = await JsonInterceptor.post(`${apiEndpoint}`, body);
    return dispatch({
      type: AuthType.CHEK_CAPTCHA_SUCCESS,
      payload: data
    });
  } catch (error) {
    const message = error && error.data?.message ? error.data?.message : errorMessage();
    toast.error(message);
    return dispatch({ type: AuthType.CHEK_CAPTCHA_FAIL, error: message });
  }
};

// confirm password
export const addConfirmPassword = (body, userType) => async (dispatch) => {
  try {
    dispatch({ type: AuthType.PASSWORD_CHANGE_REQUEST });
    let apiEndpoint =
      userType === "operator" || userType === "operator_user"
        ? API_END_POINTS.operatorChangePassword
        : `${API_END_POINTS.changePassword}`;
    const { data } = await JsonInterceptor.post(`${apiEndpoint}`, body);
    return dispatch({
      type: AuthType.PASSWORD_CHANGE_SUCCESS,
      payload: data
    });
  } catch (error) {
    const message = error && error.data?.message ? error.data?.message : errorMessage();
    toast.error(message);
    return dispatch({ type: AuthType.PASSWORD_CHANGE_FAIL, error: message });
  }
};

// registeration
export const registeration = (body) => async (dispatch) => {
  dispatch({ type: AuthType.USER_REGISTER_REQUEST });
  try {
    let apiEndpoint = `${API_END_POINTS.register}`;
    const { data } = await JsonInterceptor.post(`${apiEndpoint}`, body);
    return dispatch({ type: AuthType.USER_REGISTER_SUCCESS, payload: data });
  } catch (error) {
    return dispatch({
      type: AuthType.USER_REGISTER_FAIL,
      payload: error.response && error.response.data?.message ? error.response.data?.message : errorMessage(),
      data: error.response && error.response?.data?.data && error.response?.data?.data
    });
  }
};

export const getProfile = (userType) => async (dispatch) => {
  try {
    dispatch({ type: AuthType.GET_PROFILE_REQUEST });
    let apiEndpoint =
      userType === "operator" || userType === "operator_user"
        ? API_END_POINTS.operatorGetProfile
        : `${API_END_POINTS.getProfile}`;
    const { data } = await JsonInterceptor.get(`${apiEndpoint}`);
    return dispatch({
      type: AuthType.GET_PROFILE_SUCCESS,
      payload: data
    });
  } catch (error) {
    const message = error && error.data?.message ? error.data?.message : errorMessage();
    toast.error(message);
    return dispatch({ type: AuthType.GET_PROFILE_FAIL, error: message });
  }
};

//Get state and city
export const getAddressByZip = (id) => async (dispatch) => {
  try {
    dispatch({ type: AuthType.GET_ADDRESS_REQUEST });
    let apiEndpoint = `https://www.prominentgames.com/us_zip_city/get.php?zip_code=`;
    const { data } = await axios.get(`${apiEndpoint}${id}`);
    return dispatch({
      type: AuthType.GET_ADDRESS_SUCCESS,
      payload: data
    });
  } catch (error) {
    toast.error(error);
    return dispatch({ type: AuthType.GET_ADDRESS_FAIL, error: error });
  }
};

export const updateProfile = (body, userType) => async (dispatch) => {
  try {
    dispatch({ type: AuthType.UPDATE_PROFILE_REQUEST });
    let apiEndpoint =
      userType === "operator" || userType === "operator_user"
        ? API_END_POINTS.operatorUpdateProfile
        : `${API_END_POINTS.updateProfile}`;
    let data;
    if (userType === "operator" || userType === "operator_user") {
      data = await axiosForMultipart.post(`${apiEndpoint}`, body);
    } else {
      data = await JsonInterceptor.post(`${apiEndpoint}`, body);
    }
    return dispatch({
      type: AuthType.UPDATE_PROFILE_SUCCESS,
      payload: data.data
    });
  } catch (error) {
    const message = error && error.data?.message ? error.data?.message : errorMessage();
    toast.error(message);
    return dispatch({ type: AuthType.UPDATE_PROFILE_FAIL, error: message });
  }
};

export const resendVerifyEmail = (body) => async (dispatch) => {
  try {
    dispatch({ type: AuthType.RESEND_VERIFY_EMAIL_REQUEST });
    let apiEndpoint = `${API_END_POINTS.resendVerifyEmail}`;
    const { data } = await JsonInterceptor.post(`${apiEndpoint}`, body);
    return dispatch({
      type: AuthType.RESEND_VERIFY_EMAIL_SUCCESS,
      payload: data
    });
  } catch (error) {
    const message = error && error.data?.message ? error.data?.message : errorMessage();
    toast.error(message);
    return dispatch({ type: AuthType.RESEND_VERIFY_EMAIL_FAIL, error: message });
  }
};
export const verifyEmail = (body) => async (dispatch) => {
  try {
    dispatch({ type: AuthType.VERIFY_EMAIL_REQUEST });
    let apiEndpoint = `${API_END_POINTS.verifyEmail}`;
    const { data } = await JsonInterceptor.get(`${apiEndpoint}/${body}`);
    return dispatch({
      type: AuthType.VERIFY_EMAIL_SUCCESS,
      payload: data
    });
  } catch (error) {
    const message = error && error.data?.message ? error.data?.message : errorMessage();
    toast.error(message);
    return dispatch({ type: AuthType.VERIFY_EMAIL_FAIL, error: message });
  }
};

// reset password
export const resetPassword = (body) => async (dispatch) => {
  try {
    dispatch({ type: AuthType.RESET_PASSWORD_REQUEST });
    let apiEndpoint = `${API_END_POINTS.resetPassword}`;
    const { data } = await JsonInterceptor.post(`${apiEndpoint}`, body);
    return dispatch({
      type: AuthType.RESET_PASSWORD_SUCCESS,
      payload: data
    });
  } catch (error) {
    const message = error && error.data.message ? error.data.message : errorMessage();
    toast.error(message);
    return dispatch({ type: AuthType.RESET_PASSWORD_FAIL, error: message });
  }
};
export const getSoftwareList = () => async (dispatch) => {
  try {
    dispatch({ type: AuthType.GET_SOFTWARE_LIST_REQUEST });
    let apiEndpoint = `${API_END_POINTS.getSoftwareList}`;
    const { data } = await JsonInterceptor.get(`${apiEndpoint}`);
    return dispatch({
      type: AuthType.GET_SOFTWARE_LIST_SUCCESS,
      payload: data
    });
  } catch (error) {
    const message = error && error.data.message ? error.data.message : errorMessage();
    toast.error(message);
    return dispatch({ type: AuthType.GET_SOFTWARE_LIST_FAIL, error: message });
  }
};
export const updateSoftware = (body, setLodingTime) => async (dispatch) => {
  try {
    dispatch({ type: AuthType.GET_SOFTWARE_UPDATE_REQUEST });
    let apiEndpoint = `${API_END_POINTS.UpdateSoftware}`;
    const { data } = await axiosForMultipartLocationAPI(true).post(`${apiEndpoint}`, body, {
      onUploadProgress: (data) => {
        setLodingTime(uploadProgress(data));
      }
    });
    return dispatch({
      type: AuthType.GET_SOFTWARE_UPDATE_SUCCESS,
      payload: data
    });
  } catch (error) {
    const message = error && error.data.message ? error.data.message : errorMessage();
    toast.error(message);
    return dispatch({ type: AuthType.GET_SOFTWARE_UPDATE_FAIL, error: message });
  }
};
export const getAppVersion = () => async (dispatch) => {
  try {
    dispatch({ type: AuthType.GET_APPLICATION_VERSION_LIST_REQUEST });
    let apiEndpoint = `${API_END_POINTS.getApplicationVersionList}`;
    const { data } = await JsonInterceptor.get(`${apiEndpoint}`);
    return dispatch({
      type: AuthType.GET_APPLICATION_VERSION_LIST_SUCCESS,
      payload: data
    });
  } catch (error) {
    const message = error && error.data.message ? error.data.message : errorMessage();
    toast.error(message);
    return dispatch({ type: AuthType.GET_APPLICATION_VERSION_LIST_FAIL, error: message });
  }
};

export const UpdateApplicationVersion = (body) => async (dispatch) => {
  try {
    dispatch({ type: AuthType.GET_SOFTWARE_UPDATE_REQUEST });
    let apiEndpoint = `${API_END_POINTS.UpdateApplicationVersion}`;
    const { data } = await JsonInterceptor.post(`${apiEndpoint}`, body);
    return dispatch({
      type: AuthType.GET_SOFTWARE_UPDATE_SUCCESS,
      payload: data
    });
  } catch (error) {
    const message = error && error.data.message ? error.data.message : errorMessage();
    toast.error(message);
    return dispatch({ type: AuthType.GET_SOFTWARE_UPDATE_FAIL, error: message });
  }
};
export const downloadKioskSoftware = (body) => async (dispatch) => {
  try {
    // dispatch({ type: AuthType.GET_SOFTWARE_UPDATE_REQUEST });
    let apiEndpoint = `${API_END_POINTS.downloadKioskSoftware}`;
    const { data } = await JsonInterceptor.post(`${apiEndpoint}`, body);
    return data;
    // return dispatch({
    //   type: AuthType.GET_SOFTWARE_UPDATE_SUCCESS,
    //   payload: data
    // });
  } catch (error) {
    const message = error && error.data.message ? error.data.message : errorMessage();
    toast.error(message);
    // return dispatch({ type: AuthType.GET_SOFTWARE_UPDATE_FAIL, error: message });
  }
};
