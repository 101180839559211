import { OperatorType } from "./type";

// Organizers intial state
const initialAuthState = {
  operators: null,
  operator: null,
  isLoading: false,
  statusCode: null,
  operatorDropdownList: []
};

export const reducer = (state = initialAuthState, action) => {
  switch (action.type) {
    case OperatorType.GET_OPERATORS_REQUEST:
      return { ...state, isLoading: true };
    case OperatorType.GET_OPERATORS_SUCCESS:
      return { ...state, isLoading: false, operators: action.payload.data };
    case OperatorType.GET_OPERATORS_FAIL:
      return { ...state, isLoading: false, error: action.error };
    case OperatorType.GET_SINGLE_OPERATOR_REQUEST:
      return { ...state, isLoading: true, statusCode: null };
    case OperatorType.GET_SINGLE_OPERATOR_SUCCESS:
      return { ...state, isLoading: false, operator: action.payload };
    case OperatorType.GET_SINGLE_OPERATOR_FAIL:
      return { ...state, isLoading: false, error: action.error, statusCode: action.statusCode };
    case OperatorType.GET_OPERATOR_DROPDOWN_SUCCESS:
      return { ...state, isLoading: false, operatorDropdownList: action.payload.data };
    default:
      return state;
  }
};
