export const LocationType = {
  GET_LOCATIONS_REQUEST: "GET_LOCATIONS_REQUEST",
  GET_LOCATIONS_SUCCESS: "GET_LOCATIONS_SUCCESS",
  GET_LOCATIONS_FAIL: "GET_LOCATIONS_FAIL",
  UPDATE_STATUS_REQUEST: "UPDATE_STATUS_REQUEST",
  UPDATE_STATUS_SUCCESS: "UPDATE_STATUS_SUCCESS",
  UPDATE_STATUS_FAIL: "UPDATE_STATUS_FAIL",
  CREATE_LOCATION_REQUEST: "CREATE_LOCATION_REQUEST",
  CREATE_LOCATION_SUCCESS: "CREATE_LOCATION_SUCCESS",
  CREATE_LOCATION_FAIL: "CREATE_LOCATION_FAIL",
  UPDATE_LOCATION_REQUEST: "UPDATE_LOCATION_REQUEST",
  UPDATE_LOCATION_SUCCESS: "UPDATE_LOCATION_SUCCESS",
  UPDATE_LOCATION_FAIL: "UPDATE_LOCATION_FAIL",
  GET_SINGLE_LOCATION_REQUEST: "GET_SINGLE_LOCATION_REQUEST",
  GET_SINGLE_LOCATION_SUCCESS: "GET_SINGLE_LOCATION_SUCCESS",
  GET_SINGLE_LOCATION_FAIL: "GET_SINGLE_LOCATION_FAIL",
  DELETE_DOC_REQUEST: "DELETE_DOC_REQUEST",
  DELETE_DOC_SUCCESS: "DELETE_DOC_SUCCESS",
  DELETE_DOC_FAIL: "DELETE_DOC_FAIL",
  GET_LOCATION_DROPDOWN_REQUEST: "GET_LOCATION_DROPDOWN_REQUEST",
  GET_LOCATION_DROPDOWN_SUCCESS: "GET_LOCATION_DROPDOWN_SUCCESS",
  GET_LOCATION_DROPDOWN_FAIL: "GET_LOCATION_DROPDOWN_FAIL",
  GET_LOCATION_DASHBOARD_REQUEST: "GET_LOCATION_DASHBOARD_REQUEST",
  GET_LOCATION_DASHBOARD_SUCCESS: "GET_LOCATION_DASHBOARD_SUCCESS",
  GET_LOCATION_DASHBOARD_FAIL: "GET_LOCATION_DASHBOARD_FAIL",
  LOGOUT_KIOSK_REQUEST: "LOGOUT_KIOSK_REQUEST",
  LOGOUT_KIOSK_SUCCESS: "LOGOUT_KIOSK_SUCCESS",
  LOGOUT_KIOSK_FAIL: "LOGOUT_KIOSK_FAIL",
  CASH_MANAGEMENT_REQUEST: "CASH_MANAGEMENT_REQUEST",
  CASH_MANAGEMENT_SUCCESS: "CASH_MANAGEMENT_SUCCESS",
  CASH_MANAGEMENT_FAIL: "CASH_MANAGEMENT_FAIL",
  TRANSACTION_REPORT_REQUEST: "TRANSACTION_REPORT_REQUEST",
  TRANSACTION_REPORT_SUCCESS: "TRANSACTION_REPORT_SUCCESS",
  TRANSACTION_REPORT_FAIL: "TRANSACTION_REPORT_FAIL",
  CYCLE_ITEM_REPORT_REQUEST: "CYCLE_ITEM_REPORT_REQUEST",
  CYCLE_ITEM_REPORT_SUCCESS: "CYCLE_ITEM_REPORT_SUCCESS",
  CYCLE_ITEM_REPORT_FAIL: "CYCLE_ITEM_REPORT_FAIL",
  CYCLE_ITEM_REPORT_ALL_REQUEST: "CYCLE_ITEM_REPORT_ALL_REQUEST",
  CYCLE_ITEM_REPORT_ALL_SUCCESS: "CYCLE_ITEM_REPORT_ALL_SUCCESS",
  CYCLE_ITEM_REPORT_ALL_FAIL: "CYCLE_ITEM_REPORT_ALL_FAIL",
  DROPDOWN_LIST_REQUEST: "DROPDOWN_LIST_REQUEST",
  DROPDOWN_LIST_SUCCESS: "DROPDOWN_LIST_SUCCESS",
  DROPDOWN_LIST_FAIL: "DROPDOWN_LIST_FAIL"
};
