import { createContext, useState, useMemo, useEffect } from "react";
import { createTheme } from "@mui/material/styles";

// color design tokens export
export const tokens = () => ({
  grey: {
    100: "#e0e0e0",
    200: "#c2c2c2",
    300: "#a3a3a3",
    400: "#858585",
    // 500: "#666666",
    500: "#757575",
    600: "#525252",
    700: "#252729",
    800: "#1A1C1E",
    900: "#141414"
  },
  primary: {
    900: "#1A1C1E", // theme primary color
    200: "#ffa00026"
  },
  secondary: {
    900: "#FFA000" // theme secondary color
  },
  white: {
    900: "#ffffff"
  },
  success: {
    900: "#1d9142"
  },
  danger: {
    800: "#b06668",
    900: "#d01d23"
  },
  warning: {
    900: "#f3863e"
  },
  grayBg: {
    900: "#fcfcfc"
  },
  link: {
    900: "#126efd"
  },
  dark: {
    900: "#282c3a"
  },
  light: {
    900: "#F5F5F7"
  },
  bodylight: {
    800: "#E5E5E5"
  },
  linkcolor: {
    900: "#5677ff"
  },
  mailcolor: {
    800: "#E74F3D"
  },
  rolecolor: {
    800: "#48ADC5"
  }
});

// mui theme settings
export const themeSettings = (mode) => {
  const colors = tokens(mode);
  return {
    palette: {
      mode: mode,
      ...(mode === "dark"
        ? {
            // palette values for dark mode
            primary: {
              main: colors.secondary[900],
              light: colors.primary[200]
            },
            secondary: {
              main: colors.primary[900]
            },
            danger: {
              main: colors.danger[900]
            },
            dark: {
              main: colors.grey[800]
            },
            warning: {
              main: colors.warning[900]
            },
            grey: {
              extraLight: colors.grey[200],
              light: colors.grey[300],
              main: colors.grey[500]
            },
            background: {
              default: colors.grey[700],
              paper: colors.grey[800]
            },
            white: {
              main: colors.white[900]
            },
            success: {
              main: colors.success[900]
            },
            grayBg: {
              main: colors.grayBg[900]
            },
            light: {
              main: colors.light[900]
            },
            link: {
              main: colors.linkcolor[900]
            },
            mail: {
              main: colors.mailcolor[800]
            },
            role: {
              main: colors.rolecolor[800]
            }
          }
        : {
            // palette values for light mode
            primary: {
              main: colors.primary[900],
              light: colors.primary[200]
            },
            secondary: {
              main: colors.secondary[900]
            },
            danger: {
              main: colors.danger[900]
            },
            dark: {
              main: colors.grey[800]
            },
            warning: {
              main: colors.warning[900]
            },
            grey: {
              extraLight: colors.grey[200],
              light: colors.grey[300],
              main: colors.grey[500]
            },
            background: {
              default: colors.bodylight[800],
              paper: colors.light[900]
            },
            white: {
              main: colors.white[900]
            },
            success: {
              main: colors.success[900]
            },
            grayBg: {
              main: colors.grayBg[900]
            },
            light: {
              main: colors.light[900]
            },
            link: {
              main: colors.linkcolor[900]
            },
            mail: {
              main: colors.mailcolor[800]
            },
            role: {
              main: colors.rolecolor[800]
            }
          })
    },
    typography: {
      fontFamily: ["Source Sans Pro", "sans-serif"].join(","),
      fontSize: 14,
      h1: {
        fontFamily: ["Source Sans Pro", "sans-serif"].join(","),
        fontSize: 40,
        fontWeight: "bold",
        textTransform: "capitalize"
      },
      h2: {
        fontFamily: ["Source Sans Pro", "sans-serif"].join(","),
        fontSize: 32,
        fontWeight: "bold",
        textTransform: "capitalize"
      },
      h3: {
        fontFamily: ["Source Sans Pro", "sans-serif"].join(","),
        fontSize: 24,
        fontWeight: "bold",
        textTransform: "capitalize"
      },
      h4: {
        fontFamily: ["Source Sans Pro", "sans-serif"].join(","),
        fontSize: 20,
        fontWeight: "bold",
        textTransform: "capitalize"
      },
      h5: {
        fontFamily: ["Source Sans Pro", "sans-serif"].join(","),
        fontSize: 16,
        fontWeight: "bold",
        textTransform: "capitalize"
      },
      h6: {
        fontFamily: ["Source Sans Pro", "sans-serif"].join(","),
        fontSize: 14,
        fontWeight: "bold",
        textTransform: "capitalize"
      }
    },
    breakpoints: {
      values: {
        xs: 0,
        sm: 600,
        md: 900,
        lg: 1200,
        xl: 1536,
        xxl: 1800
      }
    },
    components: {
      MuiButton: {
        styleOverrides: {
          root: ({ ownerState }) => ({
            ...(ownerState.variant === "contained" &&
              ownerState.color === "primary" && {
                backgroundColor: mode === "dark" ? colors.secondary[900] : colors.primary[900],
                color: "#fff"
              }),
            ...(ownerState.variant === "outlined" &&
              ownerState.color === "primary" && {
                color: mode === "dark" ? colors.secondary[900] : colors.primary[900],
                borderColor: mode === "dark" ? colors.secondary[900] : colors.primary[900],
                svg: {
                  transition:
                    "background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms"
                },
                "&:hover": {
                  backgroundColor: mode === "dark" ? colors.secondary[900] : colors.primary[900],
                  color: "#fff",
                  svg: {
                    color: "#fff"
                  }
                }
              }),
            ...(ownerState.variant === "outlined" &&
              ownerState.color === "secondary" && {
                color: colors.secondary[900],
                borderColor: colors.secondary[900],
                svg: {
                  transition:
                    "background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms"
                },
                "&:hover": {
                  backgroundColor: colors.secondary[900],
                  color: "#fff",
                  svg: {
                    color: "#fff"
                  }
                }
              }),
            ...(ownerState.variant === "outlined" &&
              ownerState.color === "dark" && {
                color: colors.grey[800],
                borderColor: colors.grey[800],
                svg: {
                  transition:
                    "background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms"
                },
                "&:hover": {
                  backgroundColor: colors.grey[800],
                  color: "#fff",
                  svg: {
                    color: "#fff"
                  }
                }
              }),
            ...(ownerState.variant === "link" &&
              ownerState.color === "primary" && {
                fontWeight: "bold",
                color: mode === "dark" ? colors.secondary[900] : colors.primary[900],
                svg: {
                  transition:
                    "background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms"
                },
                "&:hover": {
                  background: "transparent",
                  color: colors.secondary[900],
                  svg: {
                    color: colors.secondary[900]
                  }
                }
              }),
            ...(ownerState.variant === "link" &&
              ownerState.color === "dark" && {
                fontWeight: "bold",
                color: colors.grey[500],
                svg: {
                  transition:
                    "background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms"
                },
                "&:hover": {
                  background: "transparent",
                  color: mode === "dark" ? colors.secondary[900] : colors.primary[900],
                  svg: {
                    color: mode === "dark" ? colors.secondary[900] : colors.primary[900]
                  }
                }
              })
          })
        }
      },
      MuiPaper: {
        styleOverrides: {
          root: {
            backgroundImage: "none"
          }
        }
      }
    }
  };
};

// context for color mode
export const ColorModeContext = createContext({
  toggleColorMode: () => {}
});

export const useMode = () => {
  const [mode, setMode] = useState("light");
  useEffect(() => {
    if (typeof window !== "undefined") {
      setMode(window.localStorage.getItem("theme") || "light");
    }
  }, []);

  const colorMode = useMemo(
    () => ({
      toggleColorMode: () => {
        const newMode = mode === "light" ? "dark" : "light";
        setMode(newMode);
        window.localStorage.setItem("theme", newMode);
      }
    }),
    [mode]
  );

  const theme = useMemo(() => createTheme(themeSettings(mode)), [mode]);
  return [theme, colorMode];
};
