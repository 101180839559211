import { TransactionType } from "./type";

// TransactionType intial state
const initialAuthState = {
  transactions: null,
  isLoading: false,
  isCardLoading: false,
  count: null
};

export const reducer = (state = initialAuthState, action) => {
  switch (action.type) {
    case TransactionType.GET_TRANSACTION_REQUEST:
      return { ...state, isLoading: true };
    case TransactionType.GET_TRANSACTION_SUCCESS:
      return { ...state, isLoading: false, transactions: action.payload.data };
    case TransactionType.GET_TRANSACTION_FAIL:
      return { ...state, isLoading: false };
    case TransactionType.GET_TRANSACTION_COUNT_REQUEST:
      return { ...state, isCardLoading: true };
    case TransactionType.GET_TRANSACTION_COUNT_SUCCESS:
      return { ...state, isCardLoading: false, count: action.payload.data };
    case TransactionType.GET_TRANSACTION_COUNT_FAIL:
      return { ...state, isCardLoading: false };
    default:
      return state;
  }
};
