import { Box, Drawer } from "@mui/material";
import { useState } from "react";
import DrawerContents from "./DrawerContents";
import { useTheme } from "@emotion/react";
const drawerWidth = 270;

const MobileDrawer = ({ children, container, open, onClose }) => {
  return (
    <Drawer
      variant="temporary"
      container={container}
      open={open}
      onClose={onClose}
      sx={{
        display: { xs: "block", md: "none" },
        "& .MuiDrawer-paper": {
          boxSizing: "border-box",
          width: drawerWidth,
          whiteSpace: "nowrap",
          paddingTop: "10px",
          boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px",
          height: "100vh",
          border: "none"
        }
      }}
    >
      {children}
    </Drawer>
  );
};

const DesktopDrawer = ({ open, children }) => {
  const { palette } = useTheme();
  return (
    <Drawer
      variant="permanent"
      sx={{
        display: { xs: "none", md: "block" },
        "& .MuiDrawer-paper": {
          boxShadow: `0 0 3px ${palette.mode === "dark" ? "palette.secondary.main" : palette.grey[400]}`,
          height: "100vh",
          border: "none",
          position: "relative",
          whiteSpace: "nowrap",
          width: drawerWidth,
          paddingTop: "5px",
          transition: (theme) =>
            theme.transitions.create("width", {
              easing: theme.transitions.easing.sharp,
              duration: theme.transitions.duration.enteringScreen
            }),
          boxSizing: "border-box",
          ...(!open && {
            overflowX: "hidden",
            transition: (theme) =>
              theme.transitions.create("width", {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.leavingScreen
              }),
            width: (theme) => ({ xs: theme.spacing(7), sm: theme.spacing(9) })
          })
        }
      }}
      open={open}
    >
      {children}
    </Drawer>
  );
};

export default function SidebarComponent({ toggled, handleToggleSidebar }) {
  const [isCollapsed, setIsCollapsed] = useState(true);
  const drawer = (
    <DrawerContents
      toggled={window.innerWidth < 901 ? toggled : isCollapsed}
      onClick={() => (window.innerWidth < 901 ? handleToggleSidebar(false) : setIsCollapsed(!isCollapsed))}
    />
  );
  return (
    <>
      <Box component="nav" aria-label="menu items" className="pro-sidebar">
        <MobileDrawer open={toggled} onClose={() => handleToggleSidebar(false)} children={drawer} />
        <DesktopDrawer open={isCollapsed} children={drawer} />
      </Box>
    </>
  );
}
