import { DistributorType } from "./type";

const initialAuthState = {
  distributors: null,
  distributor: null,
  isLoading: false,
  statusCode: null,
  distributorDropdownList: []
};

export const reducer = (state = initialAuthState, action) => {
  switch (action.type) {
    case DistributorType.GET_DISTRIBUTORS_REQUEST:
      return { ...state, isLoading: true };
    case DistributorType.GET_DISTRIBUTORS_SUCCESS:
      return { ...state, isLoading: false, distributors: action.payload.data };
    case DistributorType.GET_DISTRIBUTORS_FAIL:
      return { ...state, isLoading: false, error: action.error };
    case DistributorType.GET_SINGLE_DISTRIBUTOR_REQUEST:
      return { ...state, isLoading: true, statusCode: null };
    case DistributorType.GET_SINGLE_DISTRIBUTOR_SUCCESS:
      return { ...state, isLoading: false, distributor: action.payload };
    case DistributorType.GET_SINGLE_DISTRIBUTOR_FAIL:
      return { ...state, isLoading: false, error: action.error, statusCode: action.statusCode };
    case DistributorType.GET_DISTRIBUTOR_DROPDOWN_SUCCESS:
      return { ...state, isLoading: false, distributorDropdownList: action.payload.data };
    default:
      return state;
  }
};
