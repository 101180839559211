import "./style.css";
import { Box } from "@mui/material";
import { useTheme } from "@emotion/react";
import orangeIcon from "../../media/images/logo.png";

export default function GlobalLoader() {
  const { palette } = useTheme();

  return (
    <Box
      className="globalLoader"
      sx={{ background: `${palette.mode === "light" ? palette.light.main : palette.dark.main} !important` }}
    >
      {/* <Box className="loaderBack">
        <Box className="loader"></Box>
      </Box> */}
      <Box className="loaderBack">
        <img src={orangeIcon} alt="Spinzy Play" width={180} />
      </Box>
    </Box>
  );
}
