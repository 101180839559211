import { statementType } from "./type";

// Auth intial state
const initialAuthState = {
  superAdmin: [],
  isSuperLoading: false,
  superError: null,
  admin: [],
  isLoading: false,
  error: null,
  operatorStatement: [],
  operatorLoading: false,
  operatorError: null,
  LocationStatement: [],
  LocationLoading: false,
  LocationError: false,
  generateLoad: false,
  statusRequest: false
};
export const reducer = (state = initialAuthState, action) => {
  switch (action.type) {
    case statementType.ADMIN_LIST_REQUEST:
      return { ...state, isLoading: true, error: null };
    case statementType.ADMIN_LIST_SUCCESS:
      return { ...state, admin: action.payload?.data, isLoading: false, error: null };
    case statementType.ADMIN_LIST_FAIL:
      return { ...state, isLoading: false, error: action.payload };
    case statementType.SUPER_ADMIN_LIST_REQUEST:
      return { ...state, isSuperLoading: true, superError: null };
    case statementType.SUPER_ADMIN_LIST_SUCCESS:
      return { ...state, superAdmin: action.payload?.data, isSuperLoading: false, superError: null };
    case statementType.SUPER_ADMIN_LIST_FAIL:
      return { ...state, isSuperLoading: false, superError: action.payload };
    case statementType.OPERATOR_LIST_REQUEST:
      return { ...state, operatorLoading: true, operatorError: action.payload };
    case statementType.OPERATOR_LIST_SUCCESS:
      return { ...state, operatorLoading: false, operatorStatement: action.payload?.data, operatorError: null };
    case statementType.OPERATOR_LIST_FAIL:
      return { ...state, operatorLoading: false, operatorError: action.payload };
    case statementType.LOCATION_LIST_REQUEST:
      return { ...state, LocationLoading: true, LocationError: action.payload };
    case statementType.LOCATION_LIST_SUCCESS:
      return { ...state, LocationLoading: false, LocationStatement: action.payload?.data, LocationError: null };
    case statementType.LOCATION_LIST_FAIL:
      return { ...state, LocationLoading: false, LocationError: action.payload };
    case statementType.GENERATE_REQUEST:
      return { ...state, generateLoad: true };
    case statementType.GENERATE_SUCCESS:
      return { ...state, generateLoad: false };
    case statementType.GENERATE_FAIL:
      return { ...state, generateLoad: false };
    case statementType.STATUS_REQUEST:
      return { ...state, statusRequest: true };
    case statementType.STATUS_SUCCESS:
      return { ...state, statusRequest: false };
    case statementType.STATUS_FAIL:
      return { ...state, statusRequest: false };
    default:
      return { ...state };
  }
};
