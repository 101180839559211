export const CustomerType = {
  GET_CUSTOMERS_REQUEST: "GET_CUSTOMERS_REQUEST",
  GET_CUSTOMERS_SUCCESS: "GET_CUSTOMERS_SUCCESS",
  GET_CUSTOMERS_FAIL: "GET_CUSTOMERS_FAIL",
  UPDATE_STATUS_REQUEST: "UPDATE_STATUS_REQUEST",
  UPDATE_STATUS_SUCCESS: "UPDATE_STATUS_SUCCESS",
  UPDATE_STATUS_FAIL: "UPDATE_STATUS_FAIL",
  GET_CUSTOMER_DROPDOWN_REQUEST: "GET_CUSTOMER_DROPDOWN_REQUEST",
  GET_CUSTOMER_DROPDOWN_SUCCESS: "GET_CUSTOMER_DROPDOWN_SUCCESS",
  GET_CUSTOMER_DROPDOWN_FAIL: "GET_CUSTOMER_DROPDOWN_FAIL",
  GET_CUSTOMER_BALANCE_REQUEST: "GET_CUSTOMER_BALANCE_REQUEST",
  GET_CUSTOMER_BALANCE_SUCCESS: "GET_CUSTOMER_BALANCE_SUCCESS",
  GET_CUSTOMER_BALANCE_FAIL: "GET_CUSTOMER_BALANCE_FAIL"
};
