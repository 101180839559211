import { KioskType } from "./type";

// Organizers intial state
const initialAuthState = {
  kiosks: null,
  kiosk: null,
  isLoading: false,
  isFormLoading: false,
  countKiosk: null,
  isCardLoading: false
};

export const reducer = (state = initialAuthState, action) => {
  switch (action.type) {
    case KioskType.GET_KIOSKS_REQUEST:
      return { ...state, isLoading: true };
    case KioskType.GET_KIOSKS_SUCCESS:
      return { ...state, isLoading: false, kiosks: action.payload.data };
    case KioskType.GET_KIOSKS_FAIL:
      return { ...state, isLoading: false };
    case KioskType.GET_KIOSK_REQUEST:
      return { ...state, isLoading: true };
    case KioskType.GET_KIOSK_SUCCESS:
      return { ...state, isLoading: false, kiosk: action.payload.data };
    case KioskType.GET_KIOSK_FAIL:
      return { ...state, isLoading: false };
    case KioskType.DELETE_KIOS_MODAL_REQUEST:
      return { ...state, isLoading: true };
    case KioskType.DELETE_KIOS_MODAL_SUCCESS:
      return { ...state, isLoading: false };
    case KioskType.DELETE_KIOS_MODAL_FAIL:
      return { ...state, isLoading: false };
    case KioskType.UPDATE_KIOSK_BY_DESTRIBUTOR_REQUEST:
      return { ...state, isFormLoading: true, isLoading: true };
    case KioskType.UPDATE_KIOSK_BY_DESTRIBUTOR_SUCCESS:
      return { ...state, isFormLoading: true, isLoading: false };
    case KioskType.UPDATE_KIOSK_BY_DESTRIBUTOR_FAIL:
      return { ...state, isFormLoading: true, isLoading: false };
    case KioskType.COUNT_KIOSK_REQUEST:
      return { ...state, isCardLoading: true };
    case KioskType.COUNT_KIOSK_SUCCESS:
      return { ...state, isCardLoading: false, countKiosk: action.payload.data };
    case KioskType.COUNT_KIOSK_FAIL:
      return { ...state, isCardLoading: false };
    default:
      return state;
  }
};
