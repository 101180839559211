export const statementType = {
  ADMIN_LIST_REQUEST: "ADMIN_LIST_REQUEST",
  ADMIN_LIST_SUCCESS: "ADMIN_LIST_SUCCESS",
  ADMIN_LIST_FAIL: "ADMIN_LIST_FAIL",
  SUPER_ADMIN_LIST_REQUEST: "SUPER_ADMIN_LIST_REQUEST",
  SUPER_ADMIN_LIST_SUCCESS: "SUPER_ADMIN_LIST_SUCCESS",
  SUPER_ADMIN_LIST_FAIL: "SUPER_ADMIN_LIST_FAIL",
  OPERATOR_LIST_REQUEST: "OPERATOR_LIST_REQUEST",
  OPERATOR_LIST_SUCCESS: "OPERATOR_LIST_SUCCESS",
  OPERATOR_LIST_FAIL: "OPERATOR_LIST_FAIL",
  LOCATION_LIST_REQUEST: "LOCATION_LIST_REQUEST",
  LOCATION_LIST_SUCCESS: "LOCATION_LIST_SUCCESS",
  LOCATION_LIST_FAIL: "LOCATION_LIST_FAIL",
  GENERATE_REQUEST: "GENERATE_REQUEST",
  GENERATE_SUCCESS: "GENERATE_SUCCESS",
  GENERATE_FAIL: "GENERATE_FAIL",
  LOCATION_REQUEST: "LOCATION_REQUEST",
  LOCATION_SUCCESS: "LOCATION_SUCCESS",
  LOCATION_FAIL: "LOCATION_FAIL",
  STATUS_REQUEST: "STATUS_REQUEST",
  STATUS_SUCCESS: "STATUS_SUCCESS",
  STATUS_FAIL: "STATUS_FAIL"
};
