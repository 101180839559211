import { AdvertiseType } from "./type";

// Organizers intial state
const initialAuthState = {
  advertises: null,
  advertise: null,
  isLoading: false
};

export const reducer = (state = initialAuthState, action) => {
  switch (action.type) {
    case AdvertiseType.GET_ADVERTISES_REQUEST:
      return { ...state, isLoading: true };
    case AdvertiseType.GET_ADVERTISES_SUCCESS:
      return { ...state, isLoading: false, advertises: action.payload.data };
    case AdvertiseType.GET_ADVERTISES_FAIL:
      return { ...state, isLoading: false };
    case AdvertiseType.GET_ADVERTISE_REQUEST:
      return { ...state, isLoading: true };
    case AdvertiseType.GET_ADVERTISE_SUCCESS:
      return { ...state, isLoading: false, advertise: action.payload.data };
    case AdvertiseType.GET_ADVERTISE_FAIL:
      return { ...state, isLoading: false };
    default:
      return state;
  }
};
