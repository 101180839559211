import { combineReducers } from "redux";
import * as auth from "../pages/login/redux/reducer.js";
import * as operators from "../pages/operators/redux/reducer.js";
import * as users from "../pages/users/redux/reducer.js";
import * as advertises from "../pages/advertises/redux/reducer.js";
import * as locations from "../pages/locations/redux/reducer.js";
import * as customers from "../pages/customers/redux/reducer.js";
import * as transactions from "../pages/transactions/redux/reducer.js";
import * as distributors from "../pages/distributors/redux/reducer.js";
import * as kiosk from "../pages/kiosks/redux/reducer.js";
import * as invoices from "../pages/invoices/redux/reducer.js";
import * as statement from "../pages/statement/redux/reducer.js";
import * as settings from "../pages/settings/reducer/reducer.js";
import * as dashboard from "../pages/dashboard/redux/reducer.js";

const appReducer = combineReducers({
  auth: auth.reducer,
  operators: operators.reducer,
  users: users.reducer,
  advertises: advertises.reducer,
  locations: locations.reducer,
  customers: customers.reducer,
  transactions: transactions.reducer,
  distributors: distributors.reducer,
  kiosk: kiosk.reducer,
  invoices: invoices.reducer,
  statement: statement.reducer,
  settings: settings.reducer,
  dashboard: dashboard.reducer
});

export const rootReducer = (state, action) => {
  if (action.type === "LOG_OUT") {
    localStorage.clear();
    return appReducer(undefined, action);
  }
  return appReducer(state, action);
};
