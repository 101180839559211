export const OperatorType = {
  GET_OPERATORS_REQUEST: "GET_OPERATORS_REQUEST",
  GET_OPERATORS_SUCCESS: "GET_OPERATORS_SUCCESS",
  GET_OPERATORS_FAIL: "GET_OPERATORS_FAIL",
  UPDATE_STATUS_REQUEST: "UPDATE_STATUS_REQUEST",
  UPDATE_STATUS_SUCCESS: "UPDATE_STATUS_SUCCESS",
  UPDATE_STATUS_FAIL: "UPDATE_STATUS_FAIL",
  CREATE_OPERATOR_REQUEST: "CREATE_OPERATOR_REQUEST",
  CREATE_OPERATOR_SUCCESS: "CREATE_OPERATOR_SUCCESS",
  CREATE_OPERATOR_FAIL: "CREATE_OPERATOR_FAIL",
  UPDATE_OPERATOR_REQUEST: "UPDATE_OPERATOR_REQUEST",
  UPDATE_OPERATOR_SUCCESS: "UPDATE_OPERATOR_SUCCESS",
  UPDATE_OPERATOR_FAIL: "UPDATE_OPERATOR_FAIL",
  GET_SINGLE_OPERATOR_REQUEST: "GET_SINGLE_OPERATOR_REQUEST",
  GET_SINGLE_OPERATOR_SUCCESS: "GET_SINGLE_OPERATOR_SUCCESS",
  GET_SINGLE_OPERATOR_FAIL: "GET_SINGLE_OPERATOR_FAIL",
  DELETE_DOC_REQUEST: "DELETE_DOC_REQUEST",
  DELETE_DOC_SUCCESS: "DELETE_DOC_SUCCESS",
  DELETE_DOC_FAIL: "DELETE_DOC_FAIL",
  GET_OPERATOR_DROPDOWN_REQUEST: "GET_OPERATOR_DROPDOWN_REQUEST",
  GET_OPERATOR_DROPDOWN_SUCCESS: "GET_OPERATOR_DROPDOWN_SUCCESS",
  GET_OPERATOR_DROPDOWN_FAIL: "GET_OPERATOR_DROPDOWN_FAIL",
  UPDATE_KIOSK_KEYS_REQUEST: "UPDATE_KIOSK_KEYS_REQUEST",
  UPDATE_KIOSK_KEYS_SUCCESS: "UPDATE_KIOSK_KEYS_SUCCESS",
  UPDATE_KIOSK_KEYS_FAIL: "UPDATE_KIOSK_KEYS_FAIL"
};
