export const BellFillIcon = ({ className, width, height }) => {
  return (
    <svg
      className={className}
      width={width ? width : "25px"}
      height={height ? height : "25px"}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 -960 960 960"
    >
      <path
        fill="currentColor"
        d="M160-200v-80h80v-280q0-83 50-147.5T420-792v-28q0-25 17.5-42.5T480-880q25 0 42.5 17.5T540-820v28q80 20 130 84.5T720-560v280h80v80H160ZM480-80q-33 0-56.5-23.5T400-160h160q0 33-23.5 56.5T480-80Z"
      />
    </svg>
  );
};

export const DashboardIcon = ({ className, width, height }) => {
  return (
    <svg
      className={className}
      width={width ? width : "25px"}
      height={height ? height : "25px"}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 -960 960 960"
    >
      <path
        fill="currentColor"
        d="M212.31-140q-29.92 0-51.12-21.19Q140-182.39 140-212.31v-535.38q0-29.92 21.19-51.12Q182.39-820 212.31-820h535.38q29.92 0 51.12 21.19Q820-777.61 820-747.69v535.38q0 29.92-21.19 51.12Q777.61-140 747.69-140H212.31ZM410-200v-250H200v237.69q0 5.39 3.46 8.85t8.85 3.46H410Zm60 0h277.69q5.39 0 8.85-3.46t3.46-8.85V-450H470v250ZM200-510h560v-237.69q0-5.39-3.46-8.85t-8.85-3.46H212.31q-5.39 0-8.85 3.46t-3.46 8.85V-510Z"
      />
    </svg>
  );
};

export const UserFillIcon = ({ className, width, height }) => {
  return (
    <svg
      className={className}
      width={width ? width : "25px"}
      height={height ? height : "25px"}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 -960 960 960"
    >
      <path
        fill="currentColor"
        d="M480-481q-66 0-108-42t-42-108q0-66 42-108t108-42q66 0 108 42t42 108q0 66-42 108t-108 42ZM160-160v-94q0-38 19-65t49-41q67-30 128.5-45T480-420q62 0 123 15.5T731-360q31 14 50 41t19 65v94H160Z"
      />
    </svg>
  );
};

export const UserIcon = ({ className, width, height }) => {
  return (
    <svg
      className={className}
      width={width ? width : "25px"}
      height={height ? height : "25px"}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 -960 960 960"
    >
      <path
        fill="currentColor"
        d="M480-481q-66 0-108-42t-42-108q0-66 42-108t108-42q66 0 108 42t42 108q0 66-42 108t-108 42ZM160-160v-94q0-38 19-65t49-41q67-30 128.5-45T480-420q62 0 123 15.5t127.921 44.694q31.301 14.126 50.19 40.966Q800-292 800-254v94H160Zm60-60h520v-34q0-16-9.5-30.5T707-306q-64-31-117-42.5T480-360q-57 0-111 11.5T252-306q-14 7-23 21.5t-9 30.5v34Zm260-321q39 0 64.5-25.5T570-631q0-39-25.5-64.5T480-721q-39 0-64.5 25.5T390-631q0 39 25.5 64.5T480-541Zm0-90Zm0 411Z"
      />
    </svg>
  );
};

export const CloseIcon = ({ className, width, height }) => {
  return (
    <svg
      className={className}
      width={width ? width : "25px"}
      height={height ? height : "25px"}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 -960 960 960"
    >
      <path
        fill="currentColor"
        d="m249-183-66-66 231-231-231-231 66-66 231 231 231-231 66 66-231 231 231 231-66 66-231-231-231 231Z"
      />
    </svg>
  );
};

export const AddIcon = ({ className, width, height }) => {
  return (
    <svg
      className={className}
      width={width ? width : "25px"}
      height={height ? height : "25px"}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 -960 960 960"
    >
      <path fill="currentColor" d="M433-183v-250H183v-94h250v-250h94v250h250v94H527v250h-94Z" />
    </svg>
  );
};

export const LogoutIcon = ({ className, width, height }) => {
  return (
    <svg
      className={className}
      width={width ? width : "25px"}
      height={height ? height : "25px"}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 -960 960 960"
    >
      <path
        fill="currentColor"
        d="M200-120q-33 0-56.5-23.5T120-200v-560q0-33 23.5-56.5T200-840h280v80H200v560h280v80H200Zm440-160-55-58 102-102H360v-80h327L585-622l55-58 200 200-200 200Z"
      />
    </svg>
  );
};

export const StoreIcon = ({ className, width, height }) => {
  return (
    <svg
      className={className}
      width={width ? width : "25px"}
      height={height ? height : "25px"}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 -960 960 960"
    >
      <path
        fill="currentColor"
        d="M841-518v318q0 33-23.5 56.5T761-120H201q-33 0-56.5-23.5T121-200v-318q-23-21-35.5-54t-.5-72l42-136q8-26 28.5-43t47.5-17h556q27 0 47 16.5t29 43.5l42 136q12 39-.5 71T841-518Zm-272-42q27 0 41-18.5t11-41.5l-22-140h-78v148q0 21 14 36.5t34 15.5Zm-180 0q23 0 37.5-15.5T441-612v-148h-78l-22 140q-4 24 10.5 42t37.5 18Zm-178 0q18 0 31.5-13t16.5-33l22-154h-78l-40 134q-6 20 6.5 43t41.5 23Zm540 0q29 0 42-23t6-43l-42-134h-76l22 154q3 20 16.5 33t31.5 13ZM201-200h560v-282q-5 2-6.5 2H751q-27 0-47.5-9T663-518q-18 18-41 28t-49 10q-27 0-50.5-10T481-518q-17 18-39.5 28T393-480q-29 0-52.5-10T299-518q-21 21-41.5 29.5T211-480h-4.5q-2.5 0-5.5-2v282Zm560 0H201h560Z"
      />
    </svg>
  );
};

export const ChangePasswordIcon = ({ className, width, height }) => {
  return (
    <svg
      className={className}
      width={width ? width : "25px"}
      height={height ? height : "25px"}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 -960 960 960"
    >
      <path
        fill="currentColor"
        d="M520-120q-74 0-138.5-27.5T268-223l57-57q38 37 88 58.5T520-200q116 0 198-82t82-198q0-116-82-198t-198-82q-116 0-198 82t-82 198v7l73-73 57 56-170 170L30-490l57-56 73 74v-8q0-75 28.5-140.5t77-114q48.5-48.5 114-77T520-840q75 0 140.5 28.5t114 77q48.5 48.5 77 114T880-480q0 150-105 255T520-120Zm-80-200q-17 0-28.5-11.5T400-360v-120q0-17 11.5-28.5T440-520v-40q0-33 23.5-56.5T520-640q33 0 56.5 23.5T600-560v40q17 0 28.5 11.5T640-480v120q0 17-11.5 28.5T600-320H440Zm40-200h80v-40q0-17-11.5-28.5T520-600q-17 0-28.5 11.5T480-560v40Z"
      />
    </svg>
  );
};

export const ExclamationRoundFillIcon = ({ className, width, height }) => {
  return (
    <svg
      className={className}
      width={width ? width : "25px"}
      height={height ? height : "25px"}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 -960 960 960"
    >
      <path
        fill="currentColor"
        d="M479.982-280q14.018 0 23.518-9.482 9.5-9.483 9.5-23.5 0-14.018-9.482-23.518-9.483-9.5-23.5-9.5-14.018 0-23.518 9.482-9.5 9.483-9.5 23.5 0 14.018 9.482 23.518 9.483 9.5 23.5 9.5ZM453-433h60v-253h-60v253Zm27.266 353q-82.734 0-155.5-31.5t-127.266-86q-54.5-54.5-86-127.341Q80-397.681 80-480.5q0-82.819 31.5-155.659Q143-709 197.5-763t127.341-85.5Q397.681-880 480.5-880q82.819 0 155.659 31.5Q709-817 763-763t85.5 127Q880-563 880-480.266q0 82.734-31.5 155.5T763-197.684q-54 54.316-127 86Q563-80 480.266-80Z"
      />
    </svg>
  );
};

export const EditFillIcon = ({ className, width, height }) => {
  return (
    <svg
      className={className}
      width={width ? width : "25px"}
      height={height ? height : "25px"}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 -960 960 960"
    >
      <path
        fill="currentColor"
        d="M794-666 666-794l42-42q17-17 42.5-16.5T793-835l43 43q17 17 17 42t-17 42l-42 42Zm-42 42L248-120H120v-128l504-504 128 128Z"
      />
    </svg>
  );
};

export const DeleteFillIcon = ({ className, width, height }) => {
  return (
    <svg
      className={className}
      width={width ? width : "25px"}
      height={height ? height : "25px"}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 -960 960 960"
    >
      <path
        fill="currentColor"
        d="M261-120q-24 0-42-18t-18-42v-570h-41v-60h188v-30h264v30h188v60h-41v570q0 24-18 42t-42 18H261Zm106-146h60v-399h-60v399Zm166 0h60v-399h-60v399Z"
      />
    </svg>
  );
};
export const CheckIcon = ({ className, width, height }) => {
  return (
    <svg
      className={className}
      width={width ? width : "25px"}
      height={height ? height : "25px"}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 -960 960 960"
    >
      <path
        fill="currentColor"
        d="m421-298 283-283-46-45-237 237-120-120-45 45 165 166Zm59 218q-82 0-155-31.5t-127.5-86Q143-252 111.5-325T80-480q0-83 31.5-156t86-127Q252-817 325-848.5T480-880q83 0 156 31.5T763-763q54 54 85.5 127T880-480q0 82-31.5 155T763-197.5q-54 54.5-127 86T480-80Zm0-60q142 0 241-99.5T820-480q0-142-99-241t-241-99q-141 0-240.5 99T140-480q0 141 99.5 240.5T480-140Zm0-340Z"
      />
    </svg>
  );
};

export const ViewFillIcon = ({ className, width, height, ...rest }) => {
  return (
    <svg
      className={className}
      width={width ? width : "25px"}
      height={height ? height : "25px"}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 -960 960 960"
      {...rest}
    >
      <path
        fill="currentColor"
        d="M480.118-330Q551-330 600.5-379.618q49.5-49.617 49.5-120.5Q650-571 600.382-620.5q-49.617-49.5-120.5-49.5Q409-670 359.5-620.382q-49.5 49.617-49.5 120.5Q310-429 359.618-379.5q49.617 49.5 120.5 49.5Zm-.353-58Q433-388 400.5-420.735q-32.5-32.736-32.5-79.5Q368-547 400.735-579.5q32.736-32.5 79.5-32.5Q527-612 559.5-579.265q32.5 32.736 32.5 79.5Q592-453 559.265-420.5q-32.736 32.5-79.5 32.5ZM480-200q-146 0-264-83T40-500q58-134 176-217t264-83q146 0 264 83t176 217q-58 134-176 217t-264 83Z"
      />
    </svg>
  );
};

export const UpArrowIcon = ({ className, width, height }) => {
  return (
    <svg
      className={className}
      width={width ? width : "25px"}
      height={height ? height : "25px"}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 -960 960 960"
    >
      <path fill="currentColor" d="M450-160v-526L202-438l-42-42 320-320 320 320-42 42-248-248v526h-60Z" />
    </svg>
  );
};
export const DownArrowIcon = ({ className, width, height }) => {
  return (
    <svg
      className={className}
      width={width ? width : "25px"}
      height={height ? height : "25px"}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 -960 960 960"
    >
      <path fill="currentColor" d="M480-160 160-480l42-42 248 248v-526h60v526l248-248 42 42-320 320Z" />
    </svg>
  );
};

export const InfoIcon = ({ className, width, height }) => {
  return (
    <svg
      className={className}
      width={width ? width : "25px"}
      height={height ? height : "25px"}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 -960 960 960"
    >
      <path
        fill="currentColor"
        d="M453-280h60v-240h-60v240Zm26.982-314q14.018 0 23.518-9.2T513-626q0-14.45-9.482-24.225-9.483-9.775-23.5-9.775-14.018 0-23.518 9.775T447-626q0 13.6 9.482 22.8 9.483 9.2 23.5 9.2Zm.284 514q-82.734 0-155.5-31.5t-127.266-86q-54.5-54.5-86-127.341Q80-397.681 80-480.5q0-82.819 31.5-155.659Q143-709 197.5-763t127.341-85.5Q397.681-880 480.5-880q82.819 0 155.659 31.5Q709-817 763-763t85.5 127Q880-563 880-480.266q0 82.734-31.5 155.5T763-197.684q-54 54.316-127 86Q563-80 480.266-80Zm.234-60Q622-140 721-239.5t99-241Q820-622 721.188-721 622.375-820 480-820q-141 0-240.5 98.812Q140-622.375 140-480q0 141 99.5 240.5t241 99.5Zm-.5-340Z"
      />
    </svg>
  );
};

export const SearchIcon = ({ className, width, height }) => {
  return (
    <svg
      className={className}
      width={width ? width : "25px"}
      height={height ? height : "25px"}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
    >
      <path
        fill="currentColor"
        d="M15.5 14h-.79l-.28-.27C15.41 12.59 16 11.11 16 9.5 16 5.91 13.09 3 9.5 3S3 5.91 3 9.5 5.91 16 9.5 16c1.61 0 3.09-.59 4.23-1.57l.27.28v.79l5 4.99L20.49 19l-4.99-5zm-6 0C7.01 14 5 11.99 5 9.5S7.01 5 9.5 5 14 7.01 14 9.5 11.99 14 9.5 14z"
      ></path>
    </svg>
  );
};

export const FilterListIcon = ({ className, width, height }) => {
  return (
    <svg
      className={className}
      width={width ? width : "25px"}
      height={height ? height : "25px"}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 -960 960 960"
    >
      <path fill="currentColor" d="M400-240v-80h160v80H400ZM240-440v-80h480v80H240ZM120-640v-80h720v80H120Z" />
    </svg>
  );
};

export const UserRoleIcon = ({ className, width, height }) => {
  return (
    <svg
      className={className}
      width={width ? width : "25px"}
      height={height ? height : "25px"}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 -960 960 960"
    >
      <path
        fill="currentColor"
        d="m640-120-12-60q-12-5-22.5-10.5T584-204l-58 18-40-68 46-40q-2-14-2-26t2-26l-46-40 40-68 58 18q11-8 21.5-13.5T628-460l12-60h80l12 60q12 5 22.5 11t21.5 15l58-20 40 70-46 40q2 12 2 25t-2 25l46 40-40 68-58-18q-11 8-21.5 13.5T732-180l-12 60h-80ZM80-160v-112q0-33 17-62t47-44q51-26 115-44t141-18h14q6 0 12 2-29 72-24 143t48 135H80Zm600-80q33 0 56.5-23.5T760-320q0-33-23.5-56.5T680-400q-33 0-56.5 23.5T600-320q0 33 23.5 56.5T680-240ZM400-480q-66 0-113-47t-47-113q0-66 47-113t113-47q66 0 113 47t47 113q0 66-47 113t-113 47Z"
      />
    </svg>
  );
};

export const BackArrowIcon = ({ className, width, height }) => {
  return (
    <svg
      className={className}
      width={width ? width : "25px"}
      height={height ? height : "25px"}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 -960 960 960"
    >
      <path fill="currentColor" d="M560-240 320-480l240-240 56 56-184 184 184 184-56 56Z" />
    </svg>
  );
};

export const MenuIcon = ({ className, width, height }) => {
  return (
    <svg
      className={className}
      width={width ? width : "25px"}
      height={height ? height : "25px"}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 -960 960 960"
    >
      <path fill="currentColor" d="M103-212v-94h754v94H103Zm0-221v-94h754v94H103Zm0-221v-95h754v95H103Z" />
    </svg>
  );
};

export const DownloadIcon = ({ className, width, height }) => {
  return (
    <svg
      className={className}
      width={width ? width : "25px"}
      height={height ? height : "25px"}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 -960 960 960"
    >
      <path
        fill="currentColor"
        d="M205-112q-39.05 0-66.525-27.827Q111-167.655 111-207.2V-395h94v188h549v-188h94v187.529Q848-168 820-140q-28 28-66 28H205Zm275-222L251-562l68-67 114 115v-357h94v357l114-115 68 67-229 228Z"
      />
    </svg>
  );
};

export const EmptyBoxIcon = ({ className, width, height }) => {
  return (
    <svg
      className={className}
      width={width ? width : "25px"}
      height={height ? height : "25px"}
      viewBox="0 0 24 24"
      // version="1.1"
      // width="24"
      // height="24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g transform="translate(0 -1028.4)">
        <path d="m3 6l-2 10h1l-1 7h2 18 2l-1-7h1l-2-10h-18z" fill="#f39c12" transform="translate(0 1028.4)" />
        <rect fill="#f39c12" height="1" width="22" x="1" y="1051.4" />
        <path d="m4 1035.4-3 14h22.031l-3.031-14z" fill="#ecf0f1" />
        <rect fill="#95a5a6" height="4" transform="translate(0 1028.4)" width="13" x="5" y="17" />
        <path d="m1 1044.4v7h22v-7h-8.188c-0.415 1.1-1.511 2-2.812 2s-2.397-0.9-2.8125-2h-8.1875z" fill="#f1c40f" />
      </g>
    </svg>
  );
};

export const BackIcon = ({ className, height, width }) => {
  return (
    <svg
      className={className}
      width={width ? width : "25px"}
      height={height ? height : "25px"}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 -960 960 960"
    >
      <path fill="currentColor" d="M358-218 95-481l263-263 67 67-148 149h589v94H277l148 149-67 67Z" />
    </svg>
  );
};

export const AttachFileIcon = ({ className, height, width }) => {
  return (
    <svg
      className={className}
      width={width ? width : "25px"}
      height={height ? height : "25px"}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 -960 960 960"
    >
      <path
        fill="currentColor"
        d="M460-80q-91 0-155.5-62.5T240-296v-430q0-64 45.5-109T395-880q65 0 110 45t45 110v394q0 38-26 64.5T460-240q-38 0-64-28.5T370-336v-392h40v395q0 22 14.5 37.5T460-280q21 0 35.5-15t14.5-36v-395q0-48-33.5-81T395-840q-48 0-81.5 33T280-726v432q0 73 53 123.5T460-120q75 0 127.5-51T640-296v-432h40v431q0 91-64.5 154T460-80Z"
      />
    </svg>
  );
};

export const CustomerIcon = ({ className, width, height }) => {
  return (
    <svg
      className={className}
      width={width ? width : "25px"}
      height={height ? height : "25px"}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 -960 960 960"
    >
      <path
        fill="currentColor"
        d="M38-160v-94q0-35 18-63.5t50-42.5q73-32 131.5-46T358-420q62 0 120 14t131 46q32 14 50.5 42.5T678-254v94H38Zm700 0v-94q0-63-32-103.5T622-423q69 8 130 23.5t99 35.5q33 19 52 47t19 63v94H738ZM358-481q-66 0-108-42t-42-108q0-66 42-108t108-42q66 0 108 42t42 108q0 66-42 108t-108 42Zm360-150q0 66-42 108t-108 42q-11 0-24.5-1.5T519-488q24-25 36.5-61.5T568-631q0-45-12.5-79.5T519-774q11-3 24.5-5t24.5-2q66 0 108 42t42 108ZM98-220h520v-34q0-16-9.5-31T585-306q-72-32-121-43t-106-11q-57 0-106.5 11T130-306q-14 6-23 21t-9 31v34Zm260-321q39 0 64.5-25.5T448-631q0-39-25.5-64.5T358-721q-39 0-64.5 25.5T268-631q0 39 25.5 64.5T358-541Zm0 321Zm0-411Z"
      />
    </svg>
  );
};

export const DropDownArrowIcon = ({ className, width, height }) => {
  return (
    <svg
      className={className}
      width={width ? width : "25px"}
      height={height ? height : "25px"}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 -960 960 960"
    >
      <path fill="currentColor" d="M480-360 280-560h400L480-360Z" />
    </svg>
  );
};

export const DropUpArrowIcon = ({ className, width, height }) => {
  return (
    <svg
      className={className}
      width={width ? width : "25px"}
      height={height ? height : "25px"}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 -960 960 960"
    >
      <path fill="currentColor" d="m280-400 200-200 200 200H280Z" />
    </svg>
  );
};

export const ShowPasswordIcon = ({ className, width, height }) => {
  return (
    <svg
      className={className}
      width={width ? width : "25px"}
      height={height ? height : "25px"}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 -960 960 960"
    >
      <path
        fill="currentColor"
        d="M480-320q75 0 127.5-52.5T660-500q0-75-52.5-127.5T480-680q-75 0-127.5 52.5T300-500q0 75 52.5 127.5T480-320Zm0-72q-45 0-76.5-31.5T372-500q0-45 31.5-76.5T480-608q45 0 76.5 31.5T588-500q0 45-31.5 76.5T480-392Zm0 192q-146 0-266-81.5T40-500q54-137 174-218.5T480-800q146 0 266 81.5T920-500q-54 137-174 218.5T480-200Z"
      />
    </svg>
  );
};

export const HidePasswordIcon = ({ className, width, height }) => {
  return (
    <svg
      className={className}
      width={width ? width : "25px"}
      height={height ? height : "25px"}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 -960 960 960"
    >
      <path
        fill="currentColor"
        d="M792-56 624-222q-35 11-70.5 16.5T480-200q-151 0-269-83.5T40-500q21-53 53-98.5t73-81.5L56-792l56-56 736 736-56 56ZM480-320q11 0 20.5-1t20.5-4L305-541q-3 11-4 20.5t-1 20.5q0 75 52.5 127.5T480-320Zm292 18L645-428q7-17 11-34.5t4-37.5q0-75-52.5-127.5T480-680q-20 0-37.5 4T408-664L306-766q41-17 84-25.5t90-8.5q151 0 269 83.5T920-500q-23 59-60.5 109.5T772-302ZM587-486 467-606q28-5 51.5 4.5T559-574q17 18 24.5 41.5T587-486Z"
      />
    </svg>
  );
};

export const LightModeIcon = ({ className, width, height }) => {
  return (
    <svg
      className={className}
      width={width ? width : "25px"}
      height={height ? height : "25px"}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 -960 960 960"
    >
      <path
        fill="currentColor"
        d="M480-360q50 0 85-35t35-85q0-50-35-85t-85-35q-50 0-85 35t-35 85q0 50 35 85t85 35Zm0 80q-83 0-141.5-58.5T280-480q0-83 58.5-141.5T480-680q83 0 141.5 58.5T680-480q0 83-58.5 141.5T480-280ZM200-440H40v-80h160v80Zm720 0H760v-80h160v80ZM440-760v-160h80v160h-80Zm0 720v-160h80v160h-80ZM256-650l-101-97 57-59 96 100-52 56Zm492 496-97-101 53-55 101 97-57 59Zm-98-550 97-101 59 57-100 96-56-52ZM154-212l101-97 55 53-97 101-59-57Zm326-268Z"
      />
    </svg>
  );
};
export const DarkModeIcon = ({ className, width, height }) => {
  return (
    <svg
      className={className}
      width={width ? width : "25px"}
      height={height ? height : "25px"}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 -960 960 960"
    >
      <path
        fill="currentColor"
        d="M480-120q-150 0-255-105T120-480q0-150 105-255t255-105q14 0 27.5 1t26.5 3q-41 29-65.5 75.5T444-660q0 90 63 153t153 63q55 0 101-24.5t75-65.5q2 13 3 26.5t1 27.5q0 150-105 255T480-120Zm0-80q88 0 158-48.5T740-375q-20 5-40 8t-40 3q-123 0-209.5-86.5T364-660q0-20 3-40t8-40q-78 32-126.5 102T200-480q0 116 82 198t198 82Zm-10-270Z"
      />
    </svg>
  );
};
export const RefreshIcon = ({ className, width, height }) => {
  return (
    <svg
      className={className}
      width={width ? width : "25px"}
      height={height ? height : "25px"}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 -960 960 960"
    >
      <path
        fill="currentColor"
        d="M204-318q-22-38-33-78t-11-82q0-134 93-228t227-94h7l-64-64 56-56 160 160-160 160-56-56 64-64h-7q-100 0-170 70.5T240-478q0 26 6 51t18 49l-60 60ZM481-40 321-200l160-160 56 56-64 64h7q100 0 170-70.5T720-482q0-26-6-51t-18-49l60-60q22 38 33 78t11 82q0 134-93 228t-227 94h-7l64 64-56 56Z"
      />
    </svg>
  );
};
export const NavigateNextIcon = ({ className, width, height }) => {
  return (
    <svg
      className={className}
      width={width ? width : "25px"}
      height={height ? height : "25px"}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 -960 960 960"
    >
      <path fill="currentColor" d="M504-480 320-664l56-56 240 240-240 240-56-56 184-184Z" />
    </svg>
  );
};
export const TransactionIcon = ({ className, width, height }) => {
  return (
    <svg
      className={className}
      width={width ? width : "25px"}
      height={height ? height : "25px"}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 -960 960 960"
      fill="currentColor"
    >
      <path d="M480-60q-117 0-212.35-57.93-95.34-57.92-149.57-155.23v119.31h-60v-220h220v60H164.46q43.38 87.39 128.23 140.62Q377.54-120 480-120q73.46 0 137.81-27.15 64.34-27.16 112.65-74.12 48.31-46.96 77.58-110.92 29.27-63.96 31.58-137.81h59.99q-1.92 85.23-35.65 159.85-33.73 74.61-90.46 130.38Q716.77-124 641.19-92 565.61-60 480-60Zm-28.31-147.69v-50.46q-43.92-10.24-73.04-36.47-29.11-26.23-44.65-70.76L384.61-386q14.31 39.08 40.97 59.39 26.65 20.3 60.42 20.3 34.15 0 61.31-17.04 27.15-17.03 27.15-54.65 0-31.31-23.34-49.88-23.35-18.58-87.12-41.97-61.69-22.15-89-49.61-27.31-27.46-27.31-72.54 0-38.69 27.93-70.85 27.92-32.15 78.07-41v-48.46h54.62v48.46q33.69 2.62 62.04 25.35 28.34 22.73 40.88 54.65L561.85-604q-10.31-22.23-29.47-36.96-19.15-14.73-50.38-14.73-37.31 0-58.5 17.69-21.19 17.69-21.19 46T423-547.92q20.69 15.77 83.77 37.31 72 26 96.77 58.3Q628.31-420 628.31-378q0 28.62-10.58 50.23-10.58 21.62-27.65 36.35-17.08 14.73-38.89 23.27-21.81 8.53-44.88 12v48.46h-54.62ZM60.39-490q2.69-87.15 36.8-161.96 34.12-74.81 91.23-130.19 57.12-55.39 132.12-86.62Q395.54-900 480-900q115.85 0 212.35 58.12 96.5 58.11 149.57 156.58v-120.85h60v220h-220v-60h113.62Q753.31-732 668.27-786 583.23-840 480-840q-71.92 0-136.08 26.77-64.15 26.77-112.84 73.34-48.69 46.58-78.54 110.54-29.85 63.96-32.16 139.35H60.39Z" />
    </svg>
  );
};
export const PDFFillIcon = ({ className, height, width }) => {
  return (
    <svg
      className={className}
      width={width ? width : "25px"}
      height={height ? height : "25px"}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 -960 960 960"
    >
      <path
        fill="currentColor"
        d="M331-431h37v-83h48q15.725 0 26.362-10.638Q453-535.275 453-551v-48q0-15.725-10.638-26.362Q431.725-636 416-636h-85v205Zm37-120v-48h48v48h-48Zm129 120h84q15 0 26-10.638 11-10.637 11-26.362v-131q0-15.725-11-26.362Q596-636 581-636h-84v205Zm37-37v-131h47v131h-47Zm133 37h37v-83h50v-37h-50v-48h50v-37h-87v205ZM260-200q-24 0-42-18t-18-42v-560q0-24 18-42t42-18h560q24 0 42 18t18 42v560q0 24-18 42t-42 18H260ZM140-80q-24 0-42-18t-18-42v-620h60v620h620v60H140Z"
      />
    </svg>
  );
};
export const MoneyIcon = ({ className, height, width }) => {
  return (
    <svg
      className={className}
      width={width ? width : "25px"}
      height={height ? height : "25px"}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 -960 960 960"
    >
      <path
        fill="currentColor"
        d="M560-440q-50 0-85-35t-35-85q0-50 35-85t85-35q50 0 85 35t35 85q0 50-35 85t-85 35ZM280-320q-33 0-56.5-23.5T200-400v-320q0-33 23.5-56.5T280-800h560q33 0 56.5 23.5T920-720v320q0 33-23.5 56.5T840-320H280Zm80-80h400q0-33 23.5-56.5T840-480v-160q-33 0-56.5-23.5T760-720H360q0 33-23.5 56.5T280-640v160q33 0 56.5 23.5T360-400Zm440 240H120q-33 0-56.5-23.5T40-240v-440h80v440h680v80ZM280-400v-320 320Z"
      />
    </svg>
  );
};
export const RemainingIcon = ({ className, height, width }) => {
  return (
    <svg
      className={className}
      width={width ? width : "25px"}
      height={height ? height : "25px"}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 -960 960 960"
    >
      <path
        fill="currentColor"
        d="M480-80q-83 0-156-31.5T197-197q-54-54-85.5-127T80-480q0-83 31.5-156T197-763q54-54 127-85.5T480-880q83 0 156 31.5T763-763q54 54 85.5 127T880-480q0 83-31.5 156T763-197q-54 54-127 85.5T480-80ZM253-253l227-227v-320q-134 0-227 93t-93 227q0 64 24 123t69 104Z"
      />
    </svg>
  );
};
export const LocationFilledIcon = ({ className, height, width }) => {
  return (
    <svg
      className={className}
      width={width ? width : "25px"}
      height={height ? height : "25px"}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 -960 960 960"
    >
      <path
        fill="currentColor"
        d="M480-480q33 0 56.5-23.5T560-560q0-33-23.5-56.5T480-640q-33 0-56.5 23.5T400-560q0 33 23.5 56.5T480-480Zm0 400Q319-217 239.5-334.5T160-552q0-150 96.5-239T480-880q127 0 223.5 89T800-552q0 100-79.5 217.5T480-80Z"
      />
    </svg>
  );
};
export const LocationIcon = ({ className, height, width }) => {
  return (
    <svg
      className={className}
      width={width ? width : "25px"}
      height={height ? height : "25px"}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 -960 960 960"
      fill="currentColor"
    >
      <path
        xmlns="http://www.w3.org/2000/svg"
        d="M480.07-485.39q29.85 0 51.04-21.26 21.2-21.26 21.2-51.11 0-29.85-21.26-51.05Q509.79-630 479.93-630q-29.85 0-51.04 21.26-21.2 21.26-21.2 51.12 0 29.85 21.26 51.04 21.26 21.19 51.12 21.19ZM480-179.46q117.38-105.08 179.65-201.58 62.27-96.5 62.27-169.04 0-109.38-69.5-179.84-69.5-70.46-172.42-70.46-102.92 0-172.42 70.46-69.5 70.46-69.5 179.84 0 72.54 62.27 169.04 62.27 96.5 179.65 201.58Zm0 79.84Q329-230.46 253.54-343.15q-75.46-112.7-75.46-206.93 0-138.46 89.57-224.19Q357.23-860 480-860t212.35 85.73q89.57 85.73 89.57 224.19 0 94.23-75.46 206.93Q631-230.46 480-99.62Zm0-458.07Z"
      />
    </svg>
  );
};
export const DotIcon = ({ className, height, width }) => {
  return (
    <svg
      className={className}
      width={width ? width : "25px"}
      height={height ? height : "25px"}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 -960 960 960"
    >
      <path
        fill="currentColor"
        d="M480-200q-117 0-198.5-81.5T200-480q0-117 81.5-198.5T480-760q117 0 198.5 81.5T760-480q0 117-81.5 198.5T480-200Z"
      />
    </svg>
  );
};
export const PRKIcon = ({ className, height, width }) => {
  return (
    <svg
      className={className}
      width={width ? width : "25px"}
      height={height ? height : "25px"}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 -960 960 960"
    >
      <path
        fill="currentColor"
        d="M182-200q-51 0-79-35.5T82-322l42-300q9-60 53.5-99T282-760h396q60 0 104.5 39t53.5 99l42 300q7 51-21 86.5T778-200q-21 0-39-7.5T706-230l-90-90H344l-90 90q-15 15-33 22.5t-39 7.5Zm498-240q17 0 28.5-11.5T720-480q0-17-11.5-28.5T680-520q-17 0-28.5 11.5T640-480q0 17 11.5 28.5T680-440Zm-80-120q17 0 28.5-11.5T640-600q0-17-11.5-28.5T600-640q-17 0-28.5 11.5T560-600q0 17 11.5 28.5T600-560ZM310-440h60v-70h70v-60h-70v-70h-60v70h-70v60h70v70Z"
      />
    </svg>
  );
};

export const ModalIcon = ({ className, height, width }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width ?? "25px"}
      height={height ?? "25px"}
      viewBox="0 0 16 16"
      className={className}
    >
      <path
        fill="currentColor"
        d="M3 6h2v1H3V6zM6 6h7v1H6V6zM3 8h2v1H3V8zM6 8h7v1H6V8zM3 10h2v1H3v-1zM6 10h7v1H6v-1z"
      />
      <path fill="currentColor" d="M0 1v14h16V1H0zm15 13H1V4h14v10zm0-11h-1V2h1v1z" />
    </svg>
  );
};

export const SaveIcon = ({ className, height, width }) => {
  return (
    <svg
      className={className}
      width={width ? width : "25px"}
      height={height ? height : "25px"}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 -960 960 960"
    >
      <path
        fill="currentColor"
        d="M840-680v480q0 33-23.5 56.5T760-120H200q-33 0-56.5-23.5T120-200v-560q0-33 23.5-56.5T200-840h480l160 160Zm-80 34L646-760H200v560h560v-446ZM480-240q50 0 85-35t35-85q0-50-35-85t-85-35q-50 0-85 35t-35 85q0 50 35 85t85 35ZM240-560h360v-160H240v160Zm-40-86v446-560 114Z"
      />
    </svg>
  );
};
export const MailIcon = ({ className, height, width }) => {
  return (
    <svg
      className={className}
      width={width ? width : "25px"}
      height={height ? height : "25px"}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 -960 960 960"
    >
      <path
        fill="currentColor"
        d="M140-160q-24 0-42-18t-18-42v-520q0-24 18-42t42-18h680q24 0 42 18t18 42v520q0 24-18 42t-42 18H140Zm340-302 340-223v-55L480-522 140-740v55l340 223Z"
      />
    </svg>
  );
};
export const PasswordIcon = ({ className, height, width }) => {
  return (
    <svg
      className={className}
      width={width ? width : "25px"}
      height={height ? height : "25px"}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 -960 960 960"
    >
      <path
        fill="currentColor"
        d="M280-400q-33 0-56.5-23.5T200-480q0-33 23.5-56.5T280-560q33 0 56.5 23.5T360-480q0 33-23.5 56.5T280-400Zm0 160q-100 0-170-70T40-480q0-100 70-170t170-70q67 0 121.5 33t86.5 87h352l120 120-180 180-80-60-80 60-85-60h-47q-32 54-86.5 87T280-240Zm0-80q56 0 98.5-34t56.5-86h125l58 41 82-61 71 55 75-75-40-40H435q-14-52-56.5-86T280-640q-66 0-113 47t-47 113q0 66 47 113t113 47Z"
      />
    </svg>
  );
};
export const UserNew = ({ className, height, width }) => {
  return (
    <svg
      className={className}
      width={width ? width : "30px"}
      height={height ? height : "30px"}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 -960 960 960"
      fill="currentColor"
    >
      <path d="M480-492.31q-57.75 0-98.87-41.12Q340-574.56 340-632.31q0-57.75 41.13-98.87 41.12-41.13 98.87-41.13 57.75 0 98.87 41.13Q620-690.06 620-632.31q0 57.75-41.13 98.88-41.12 41.12-98.87 41.12ZM180-187.69v-88.93q0-29.38 15.96-54.42 15.96-25.04 42.66-38.5 59.3-29.07 119.65-43.61 60.35-14.54 121.73-14.54t121.73 14.54q60.35 14.54 119.65 43.61 26.7 13.46 42.66 38.5Q780-306 780-276.62v88.93H180Zm60-60h480v-28.93q0-12.15-7.04-22.5-7.04-10.34-19.11-16.88-51.7-25.46-105.42-38.58Q534.7-367.69 480-367.69q-54.7 0-108.43 13.11-53.72 13.12-105.42 38.58-12.07 6.54-19.11 16.88-7.04 10.35-7.04 22.5v28.93Zm240-304.62q33 0 56.5-23.5t23.5-56.5q0-33-23.5-56.5t-56.5-23.5q-33 0-56.5 23.5t-23.5 56.5q0 33 23.5 56.5t56.5 23.5Zm0-80Zm0 384.62Z" />
    </svg>
  );
};
export const DistributorNew = ({ className, height, width }) => {
  return (
    <svg
      className={className}
      width={width ? width : "25px"}
      height={height ? height : "25px"}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 -960 960 960"
      fill="currentColor"
    >
      <path
        xmlns="http://www.w3.org/2000/svg"
        d="M480-608.46 354.23-734.23 480-860l126.15 125.77L480-608.46ZM60.39-172.31v-150q0-29 19.65-49.11 19.65-20.12 47.65-20.89H251q18.08 0 34.35 8.85 16.26 8.85 26.5 24.69 29.76 40.92 73.8 63.69 44.04 22.77 94.35 22.77 50.92 0 95.15-22.77 44.24-22.77 73.39-63.69 11.46-15.84 27.42-24.69 15.96-8.85 33.04-8.85h123.31q28.61.77 48.15 20.89Q900-351.31 900-322.31v150H640v-94.08q-34.23 26.16-74.92 40.12-40.7 13.96-85.08 13.96-43.38 0-84.19-14.08-40.81-14.07-75.43-40.23v94.31H60.39Zm111.15-275.38q-45.38 0-77.69-32.31-32.31-32.31-32.31-77.69 0-46.39 32.31-78.19 32.31-31.81 77.69-31.81 46.38 0 78.19 31.81 31.81 31.8 31.81 78.19 0 45.38-31.81 77.69-31.81 32.31-78.19 32.31Zm616.92 0q-45.38 0-77.69-32.31-32.31-32.31-32.31-77.69 0-46.39 32.31-78.19 32.31-31.81 77.69-31.81 46.38 0 78.19 31.81 31.81 31.8 31.81 78.19 0 45.38-31.81 77.69-31.81 32.31-78.19 32.31Z"
      />
    </svg>
  );
};
export const OperatorNew = ({ className, height, width }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      width={width ? width : "25px"}
      height={height ? height : "25px"}
      viewBox="0 -960 960 960"
      fill="currentColor"
    >
      <path d="M333.85-417.69h292.3V-432q0-37.85-40.73-61.77T480-517.69q-64.69 0-105.42 23.92-40.73 23.92-40.73 61.77v14.31ZM480-570q29.15 0 49.58-20.42Q550-610.85 550-640t-20.42-49.58Q509.15-710 480-710t-49.58 20.42Q410-669.15 410-640t20.42 49.58Q450.85-570 480-570ZM100-118.46v-669.23Q100-818 121-839q21-21 51.31-21h615.38Q818-860 839-839q21 21 21 51.31v455.38Q860-302 839-281q-21 21-51.31 21H241.54L100-118.46ZM216-320h571.69q4.62 0 8.46-3.85 3.85-3.84 3.85-8.46v-455.38q0-4.62-3.85-8.46-3.84-3.85-8.46-3.85H172.31q-4.62 0-8.46 3.85-3.85 3.84-3.85 8.46v523.08L216-320Zm-56 0v-480 480Z" />
    </svg>
  );
};

export const TransactionNew = ({ className, width, height }) => {
  return (
    <svg
      className={className}
      width={width ? width : "25px"}
      height={height ? height : "25px"}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 -960 960 960"
      fill="currentColor"
    >
      <path d="M480-60q-117 0-212.35-57.93-95.34-57.92-149.57-155.23v119.31h-60v-220h220v60H164.46q43.38 87.39 128.23 140.62Q377.54-120 480-120q73.46 0 137.81-27.15 64.34-27.16 112.65-74.12 48.31-46.96 77.58-110.92 29.27-63.96 31.58-137.81h59.99q-1.92 85.23-35.65 159.85-33.73 74.61-90.46 130.38Q716.77-124 641.19-92 565.61-60 480-60Zm-28.31-147.69v-50.46q-43.92-10.24-73.04-36.47-29.11-26.23-44.65-70.76L384.61-386q14.31 39.08 40.97 59.39 26.65 20.3 60.42 20.3 34.15 0 61.31-17.04 27.15-17.03 27.15-54.65 0-31.31-23.34-49.88-23.35-18.58-87.12-41.97-61.69-22.15-89-49.61-27.31-27.46-27.31-72.54 0-38.69 27.93-70.85 27.92-32.15 78.07-41v-48.46h54.62v48.46q33.69 2.62 62.04 25.35 28.34 22.73 40.88 54.65L561.85-604q-10.31-22.23-29.47-36.96-19.15-14.73-50.38-14.73-37.31 0-58.5 17.69-21.19 17.69-21.19 46T423-547.92q20.69 15.77 83.77 37.31 72 26 96.77 58.3Q628.31-420 628.31-378q0 28.62-10.58 50.23-10.58 21.62-27.65 36.35-17.08 14.73-38.89 23.27-21.81 8.53-44.88 12v48.46h-54.62ZM60.39-490q2.69-87.15 36.8-161.96 34.12-74.81 91.23-130.19 57.12-55.39 132.12-86.62Q395.54-900 480-900q115.85 0 212.35 58.12 96.5 58.11 149.57 156.58v-120.85h60v220h-220v-60h113.62Q753.31-732 668.27-786 583.23-840 480-840q-71.92 0-136.08 26.77-64.15 26.77-112.84 73.34-48.69 46.58-78.54 110.54-29.85 63.96-32.16 139.35H60.39Z" />
    </svg>
  );
};
export const AdvertiseNew = ({ className, width, height }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      width={width ? width : "25px"}
      height={height ? height : "25px"}
      fill="currentColor"
      viewBox="0 -960 960 960"
    >
      <path d="M723.85-172.31v-120h-120v-60h120v-120h59.99v120h120v60h-120v120h-59.99ZM128.46-140q-30.31 0-51.31-21-20.99-21-20.99-51.31v-535.38q0-30.31 20.99-51.31 21-21 51.31-21h535.38q30.31 0 51.31 21 21 21 21 51.31V-560h-60v-92.31h-560v440q0 5.39 3.47 8.85 3.46 3.46 8.84 3.46h507.69v60H128.46Zm-12.31-572.31h560v-35.38q0-5.39-3.46-8.85t-8.85-3.46H128.46q-5.38 0-8.84 3.46-3.47 3.46-3.47 8.85v35.38Zm0 0V-760v47.69Z" />
    </svg>
  );
};
export const KioskNew = ({ className, height, width }) => {
  return (
    <svg
      className={className}
      width={width ? width : "25px"}
      height={height ? height : "25px"}
      xmlns="http://www.w3.org/2000/svg"
      fill="currentColor"
      viewBox="0 -960 960 960"
    >
      <path d="M260-140v-60h190v-120H172.31Q142-320 121-341q-21-21-21-51.31v-355.38Q100-778 121-799q21-21 51.31-21h615.38Q818-820 839-799q21 21 21 51.31v355.38Q860-362 839-341q-21 21-51.31 21H510v120h190v60H260ZM160-392.31q0 4.62 3.85 8.47 3.84 3.84 8.46 3.84h615.38q4.62 0 8.46-3.84 3.85-3.85 3.85-8.47v-355.38q0-4.62-3.85-8.46-3.84-3.85-8.46-3.85H172.31q-4.62 0-8.46 3.85-3.85 3.84-3.85 8.46v355.38Zm0 0V-760v380-12.31Z" />
    </svg>
  );
};

export const PrintIcon = ({ className, width, height }) => {
  return (
    <svg
      className={className}
      width={width ? width : "25px"}
      height={height ? height : "25px"}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 -960 960 960"
      fill="currentColor"
    >
      <path d="M640-640v-120H320v120h-80v-200h480v200h-80Zm-480 80h640-640Zm560 100q17 0 28.5-11.5T760-500q0-17-11.5-28.5T720-540q-17 0-28.5 11.5T680-500q0 17 11.5 28.5T720-460Zm-80 260v-160H320v160h320Zm80 80H240v-160H80v-240q0-51 35-85.5t85-34.5h560q51 0 85.5 34.5T880-520v240H720v160Zm80-240v-160q0-17-11.5-28.5T760-560H200q-17 0-28.5 11.5T160-520v160h80v-80h480v80h80Z" />
    </svg>
  );
};

export const DropdownIcone = ({ className, width, height }) => {
  return (
    <svg
      className={className}
      width={width ? width : "25px"}
      height={height ? height : "25px"}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 -960 960 960"
      fill="currentColor"
    >
      <path d="m296-358.46-42.15-42.16L480-626.77l226.15 226.15L664-358.46l-184-184-184 184Z" />
    </svg>
  );
};
export const DropdownUpIcone = ({ className, width, height }) => {
  return (
    <svg
      className={className}
      width={width ? width : "25px"}
      height={height ? height : "25px"}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 -960 960 960"
      fill="currentColor"
    >
      <path d="M480-358.46 253.85-584.62 296-626.77l184 184 184-184 42.15 42.15L480-358.46Z" />
    </svg>
  );
};
export const SettingIcone = ({ className, width, height }) => {
  return (
    <svg
      className={className}
      width={width ? width : "25px"}
      height={height ? height : "25px"}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 -960 960 960"
      fill="currentColor"
    >
      <path
        xmlns="http://www.w3.org/2000/svg"
        d="m387.69-100-15.23-121.85q-16.07-5.38-32.96-15.07-16.88-9.7-30.19-20.77L196.46-210l-92.3-160 97.61-73.77q-1.38-8.92-1.96-17.92-.58-9-.58-17.93 0-8.53.58-17.34t1.96-19.27L104.16-590l92.3-159.23 112.46 47.31q14.47-11.46 30.89-20.96t32.27-15.27L387.69-860h184.62l15.23 122.23q18 6.54 32.57 15.27 14.58 8.73 29.43 20.58l114-47.31L855.84-590l-99.15 74.92q2.15 9.69 2.35 18.12.19 8.42.19 16.96 0 8.15-.39 16.58-.38 8.42-2.76 19.27L854.46-370l-92.31 160-112.61-48.08q-14.85 11.85-30.31 20.96-15.46 9.12-31.69 14.89L572.31-100H387.69ZM440-160h78.62L533-267.15q30.62-8 55.96-22.73 25.35-14.74 48.89-37.89L737.23-286l39.39-68-86.77-65.38q5-15.54 6.8-30.47 1.81-14.92 1.81-30.15 0-15.62-1.81-30.15-1.8-14.54-6.8-29.7L777.38-606 738-674l-100.54 42.38q-20.08-21.46-48.11-37.92-28.04-16.46-56.73-23.31L520-800h-79.38l-13.24 106.77q-30.61 7.23-56.53 22.15-25.93 14.93-49.47 38.46L222-674l-39.38 68L269-541.62q-5 14.24-7 29.62t-2 32.38q0 15.62 2 30.62 2 15 6.62 29.62l-86 65.38L222-286l99-42q22.77 23.38 48.69 38.31 25.93 14.92 57.31 22.92L440-160Zm40.46-200q49.92 0 84.96-35.04 35.04-35.04 35.04-84.96 0-49.92-35.04-84.96Q530.38-600 480.46-600q-50.54 0-85.27 35.04T360.46-480q0 49.92 34.73 84.96Q429.92-360 480.46-360ZM480-480Z"
      />
    </svg>
  );
};

export const StatementsIcone = ({ className, width, height }) => {
  return (
    <svg
      className={className}
      width={width ? width : "25px"}
      height={height ? height : "25px"}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 -960 960 960"
      fill="currentColor"
    >
      <path d="M350-240h437.69q4.62 0 8.46-3.85 3.85-3.84 3.85-8.46V-357H350v117ZM160-603h130v-117H172.31q-4.62 0-8.46 3.85-3.85 3.84-3.85 8.46V-603Zm0 187h130v-127H160v127Zm12.31 176H290v-117H160v104.69q0 4.62 3.85 8.46 3.84 3.85 8.46 3.85ZM350-416h450v-127H350v127Zm0-187h450v-104.69q0-4.62-3.85-8.46-3.84-3.85-8.46-3.85H350v117ZM172.31-180Q142-180 121-201q-21-21-21-51.31v-455.38Q100-738 121-759q21-21 51.31-21h615.38Q818-780 839-759q21 21 21 51.31v455.38Q860-222 839-201q-21 21-51.31 21H172.31Z" />
    </svg>
  );
};
export const InvoiceIcone = ({ className, width, height }) => {
  return (
    <svg
      className={className}
      width={width ? width : "25px"}
      height={height ? height : "25px"}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 -960 960 960"
      fill="currentColor"
    >
      <path d="M322.33-289.23q15.05 0 25.21-10.18 10.15-10.18 10.15-25.23t-10.18-25.21Q337.33-360 322.28-360t-25.2 10.18q-10.16 10.18-10.16 25.23t10.18 25.2q10.19 10.16 25.23 10.16Zm0-155.39q15.05 0 25.21-10.18 10.15-10.18 10.15-25.23t-10.18-25.2q-10.18-10.15-25.23-10.15t-25.2 10.18q-10.16 10.18-10.16 25.23t10.18 25.2q10.19 10.15 25.23 10.15Zm0-155.38q15.05 0 25.21-10.18 10.15-10.18 10.15-25.23t-10.18-25.2q-10.18-10.16-25.23-10.16t-25.2 10.18q-10.16 10.18-10.16 25.23t10.18 25.21Q307.29-600 322.33-600Zm121.52 305.38h227.69v-59.99H443.85v59.99Zm0-155.38h227.69v-60H443.85v60Zm0-155.39h227.69v-59.99H443.85v59.99ZM212.31-140Q182-140 161-161q-21-21-21-51.31v-535.38Q140-778 161-799q21-21 51.31-21h535.38Q778-820 799-799q21 21 21 51.31v535.38Q820-182 799-161q-21 21-51.31 21H212.31Zm0-60h535.38q4.62 0 8.46-3.85 3.85-3.84 3.85-8.46v-535.38q0-4.62-3.85-8.46-3.84-3.85-8.46-3.85H212.31q-4.62 0-8.46 3.85-3.85 3.84-3.85 8.46v535.38q0 4.62 3.85 8.46 3.84 3.85 8.46 3.85ZM200-760v560-560Z" />
    </svg>
  );
};
export const LockIcon = ({ className, width, height }) => {
  return (
    <svg
      className={className}
      width={width ? width : "25px"}
      height={height ? height : "25px"}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 -960 960 960"
      fill="currentColor"
    >
      <path
        fill="currentColor"
        d="M240-80q-33 0-56.5-23.5T160-160v-400q0-33 23.5-56.5T240-640h40v-80q0-83 58.5-141.5T480-920q83 0 141.5 58.5T680-720v80h40q33 0 56.5 23.5T800-560v400q0 33-23.5 56.5T720-80H240Zm0-80h480v-400H240v400Zm240-120q33 0 56.5-23.5T560-360q0-33-23.5-56.5T480-440q-33 0-56.5 23.5T400-360q0 33 23.5 56.5T480-280ZM360-640h240v-80q0-50-35-85t-85-35q-50 0-85 35t-35 85v80ZM240-160v-400 400Z"
      />
    </svg>
  );
};

export const SubmenuIcone = ({ className, width, height }) => {
  return (
    <svg
      className={className}
      width={width ? width : "25px"}
      height={height ? height : "25px"}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 -960 960 960"
      fill="currentColor"
    >
      <path d="M252-466v-28h456v28H252Z" />
    </svg>
  );
};

export const PaypalIcone = ({ className, width, height }) => {
  return (
    <svg
      className={className}
      width={width ? width : "25px"}
      height={height ? height : "25px"}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 -960 960 960"
      fill="currentColor"
    >
      <path d="M860-722.31v484.62q0 23.53-17.08 40.61T802.31-180H157.69q-23.53 0-40.61-17.08T100-237.69v-484.62q0-23.53 17.08-40.61T157.69-780h644.62q23.53 0 40.61 17.08T860-722.31ZM145.39-617.15h669.22v-105.16q0-4.61-3.84-8.46-3.85-3.84-8.46-3.84H157.69q-4.61 0-8.46 3.84-3.84 3.85-3.84 8.46v105.16Zm0 104.76v274.7q0 4.61 3.84 8.46 3.85 3.84 8.46 3.84h644.62q4.61 0 8.46-3.84 3.84-3.85 3.84-8.46v-274.7H145.39Zm0 287V-734.61-225.39Z" />
    </svg>
  );
};

export const KisoskRepotIcone = ({ className, width, height }) => {
  return (
    <svg
      className={className}
      width={width ? width : "25px"}
      height={height ? height : "25px"}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 -960 960 960"
      fill="currentColor"
    >
      <path d="M332-82.61v-43.77h296v43.77H332Zm-7.85-114.54q-27.78 0-48.08-20.3-20.3-20.31-20.3-48.09v-553.69q0-27.78 20.3-48.08 20.3-20.31 48.08-20.31h311.7q27.78 0 48.08 20.31 20.3 20.3 20.3 48.08v553.69q0 27.78-20.3 48.09-20.3 20.3-48.08 20.3h-311.7Zm-24.61-108v39.61q0 9.23 7.69 16.92 7.69 7.7 16.92 7.7h311.7q9.23 0 16.92-7.7 7.69-7.69 7.69-16.92v-39.61H299.54Zm0-43.77h360.92v-387.93H299.54v387.93Zm0-431.7h360.92v-38.61q0-9.23-7.69-16.92-7.69-7.7-16.92-7.7h-311.7q-9.23 0-16.92 7.7-7.69 7.69-7.69 16.92v38.61Zm0 0V-843.85-780.62Zm0 475.47v64.23-64.23Z" />
    </svg>
  );
};

export const IOSIcon = ({ className, width = "25px", height = "25px", ...props }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      shapeRendering="geometricPrecision"
      textRendering="geometricPrecision"
      imageRendering="optimizeQuality"
      fillRule="evenodd"
      clipRule="evenodd"
      viewBox="0 0 640 640"
      className={className}
      {...props}
    >
      <path d="M494.782 340.02c-.803-81.025 66.084-119.907 69.072-121.832-37.595-54.993-96.167-62.552-117.037-63.402-49.843-5.032-97.242 29.362-122.565 29.362-25.253 0-64.277-28.607-105.604-27.85-54.32.803-104.4 31.594-132.403 80.245C29.81 334.457 71.81 479.58 126.816 558.976c26.87 38.882 58.914 82.56 100.997 81 40.512-1.594 55.843-26.244 104.848-26.244 48.993 0 62.753 26.245 105.64 25.406 43.606-.803 71.232-39.638 97.925-78.65 30.887-45.12 43.548-88.75 44.316-90.994-.969-.437-85.029-32.634-85.879-129.439l.118-.035zM414.23 102.178C436.553 75.095 451.636 37.5 447.514-.024c-32.162 1.311-71.163 21.437-94.253 48.485-20.729 24.012-38.836 62.28-33.993 99.036 35.918 2.8 72.591-18.248 94.926-45.272l.036-.047z" />
    </svg>
  );
};
export const AndroidIcon = ({ className, width = "25px", height = "25px", ...props }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 24 24"
      className={className}
      {...props}
    >
      <path d="M6 8l.001 9.444c0 .861.696 1.556 1.557 1.556H8v3.542C8 23.346 8.707 24 9.51 24c.806 0 1.49-.654 1.49-1.459V19h2v3.542c0 .804.707 1.458 1.511 1.458.806 0 1.489-.654 1.489-1.459V19l.444-.001c.858 0 1.556-.696 1.556-1.557V8H6zm16 1.471C22 8.666 21.305 8 20.5 8c-.805-.001-1.5.667-1.5 1.472v6.106c0 .806.694 1.422 1.5 1.422.805 0 1.5-.615 1.5-1.422V9.471zm-17 0C5 8.666 4.305 8 3.5 8 2.695 7.999 2 8.667 2 9.472v6.106C2 16.384 2.694 17 3.5 17c.805 0 1.5-.615 1.5-1.422V9.471zm9.951-7.312L15.891.3c.068-.132-.019-.3-.163-.3a.185.185 0 00-.164.105l-.949 1.878c-1.531-.737-3.544-.812-5.229 0L8.436.105A.183.183 0 008.272 0c-.144 0-.231.168-.163.3l.94 1.859C7.204 3.193 6 4.743 6 6.999h12c0-2.256-1.204-3.806-3.049-4.84zM9.501 5a.501.501 0 010-1 .5.5 0 010 1zm4.998 0a.5.5 0 110-.999.5.5 0 010 .999z" />
    </svg>
  );
};
export const TabletIcon = ({ className, width = "25px", height = "25px", ...props }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width={width}
      height={height}
      viewBox="0 0 24 24"
      version="1.1"
    >
      <g stroke="none" strokeWidth={1} fill="none" fillRule="evenodd">
        <g id="Device" transform="translate(-1008.000000, 0.000000)" fillRule="nonzero">
          <g id="pad_line" transform="translate(1008.000000, 0.000000)">
            <path
              d="M24,0 L24,24 L0,24 L0,0 L24,0 Z M12.5934901,23.257841 L12.5819402,23.2595131 L12.5108777,23.2950439 L12.4918791,23.2987469 L12.4918791,23.2987469 L12.4767152,23.2950439 L12.4056548,23.2595131 C12.3958229,23.2563662 12.3870493,23.2590235 12.3821421,23.2649074 L12.3780323,23.275831 L12.360941,23.7031097 L12.3658947,23.7234994 L12.3769048,23.7357139 L12.4804777,23.8096931 L12.4953491,23.8136134 L12.4953491,23.8136134 L12.5071152,23.8096931 L12.6106902,23.7357139 L12.6232938,23.7196733 L12.6232938,23.7196733 L12.6266527,23.7031097 L12.609561,23.275831 C12.6075724,23.2657013 12.6010112,23.2592993 12.5934901,23.257841 L12.5934901,23.257841 Z M12.8583906,23.1452862 L12.8445485,23.1473072 L12.6598443,23.2396597 L12.6498822,23.2499052 L12.6498822,23.2499052 L12.6471943,23.2611114 L12.6650943,23.6906389 L12.6699349,23.7034178 L12.6699349,23.7034178 L12.678386,23.7104931 L12.8793402,23.8032389 C12.8914285,23.8068999 12.9022333,23.8029875 12.9078286,23.7952264 L12.9118235,23.7811639 L12.8776777,23.1665331 C12.8752882,23.1545897 12.8674102,23.1470016 12.8583906,23.1452862 L12.8583906,23.1452862 Z M12.1430473,23.1473072 C12.1332178,23.1423925 12.1221763,23.1452606 12.1156365,23.1525954 L12.1099173,23.1665331 L12.0757714,23.7811639 C12.0751323,23.7926639 12.0828099,23.8018602 12.0926481,23.8045676 L12.108256,23.8032389 L12.3092106,23.7104931 L12.3186497,23.7024347 L12.3186497,23.7024347 L12.3225043,23.6906389 L12.340401,23.2611114 L12.337245,23.2485176 L12.337245,23.2485176 L12.3277531,23.2396597 L12.1430473,23.1473072 Z"
              id="MingCute"
              fillRule="nonzero"
            ></path>
            <path
              d="M18,2 C19.0543909,2 19.9181678,2.81587733 19.9945144,3.85073759 L20,4 L20,20 C20,21.0543909 19.18415,21.9181678 18.1492661,21.9945144 L18,22 L6,22 C4.94563773,22 4.08183483,21.18415 4.00548573,20.1492661 L4,20 L4,4 C4,2.94563773 4.81587733,2.08183483 5.85073759,2.00548573 L6,2 L18,2 Z M18,4 L6,4 L6,20 L18,20 L18,4 Z M12.5,16 C12.7454222,16 12.9496,16.1769086 12.9919429,16.4101355 L13,16.5 L13,17.5 C13,17.7454222 12.8230914,17.9496 12.5898645,17.9919429 L12.5,18 L11.5,18 C11.2545778,18 11.0504,17.8230914 11.0080571,17.5898645 L11,17.5 L11,16.5 C11,16.2545778 11.1769086,16.0504 11.4101355,16.0080571 L11.5,16 L12.5,16 Z"
              fill="currentColor"
            ></path>
          </g>
        </g>
      </g>
    </svg>
  );
};
export const KioskIcon = ({ className, width = "25px", height = "25px", ...props }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      fill="currentColor"
      height={height}
      width={width}
      version="1.1"
      viewBox="0 0 470.21 470.21"
      xmlSpace="preserve"
    >
      <g>
        <path d="M370.15,369.011c0-2.68-1.429-5.156-3.75-6.496l-89.011-51.394V196.243l41.399-116.665c1.036-2.921,0.17-6.178-2.182-8.197   l-18.642-16.012c-0.348-0.299-0.727-0.571-1.136-0.808L204.071,1.005c-1.938-1.119-4.275-1.315-6.37-0.532   c-2.097,0.781-3.734,2.458-4.467,4.572l-46.378,133.886c-0.273,0.79-0.413,1.62-0.413,2.455v183.107l-42.634,24.619   c-2.321,1.34-3.75,3.816-3.75,6.496c0,0.025,0.005,0.049,0.005,0.073v26.721c0,2.68,1.43,5.156,3.751,6.496l139.136,80.307   c1.16,0.67,2.454,1.004,3.749,1.004s2.59-0.335,3.75-1.005l115.929-66.931c2.319-1.339,3.748-3.813,3.75-6.49   C370.129,395.784,370.15,369.026,370.15,369.011z M262.821,192.443c-0.285,0.806-0.432,1.654-0.432,2.508v196.486l-8.209,4.74   l0.002-199.963L296.555,73.93l6.377,5.478L262.821,192.443z M161.443,142.648l42.992-124.112l79.528,45.917l-44.368,128.042   c-0.273,0.79-0.413,1.62-0.413,2.456l-0.002,201.226l-77.737-44.882V142.648z M146.443,341.815v13.811   c0,2.68,1.43,5.155,3.75,6.495l92.737,53.542c1.16,0.67,2.455,1.005,3.75,1.005s2.59-0.335,3.75-1.005l23.209-13.4   c2.32-1.34,3.75-3.815,3.75-6.495v-67.325l70.259,40.567l-100.949,58.264l-124.14-71.666L146.443,341.815z M115.064,368.6   L239.2,440.263v9.458l-124.136-71.649V368.6z M254.2,449.719v-9.457l100.938-58.257l-0.007,9.441L254.2,449.719z" />
        <path d="M272.609,63.586L215.001,30.33c-1.938-1.119-4.276-1.313-6.37-0.532c-2.097,0.782-3.734,2.458-4.467,4.573l-37.378,107.918   c-1.177,3.396,0.223,7.15,3.335,8.948l57.588,33.277c1.154,0.667,2.449,1.006,3.752,1.006c0.884,0,1.771-0.156,2.618-0.472   c2.098-0.781,3.736-2.458,4.469-4.572l37.398-107.938C277.123,69.139,275.723,65.384,272.609,63.586z M227.348,166.981   l-44.362-25.634l32.38-93.486l44.378,25.619L227.348,166.981z" />
        <path d="M227.245,201.846l-46.361-26.788c-3.585-2.073-8.173-0.845-10.246,2.741c-2.072,3.587-0.845,8.174,2.742,10.247   l46.361,26.788c1.182,0.683,2.472,1.008,3.745,1.008c2.591,0,5.111-1.345,6.501-3.749   C232.059,208.506,230.832,203.919,227.245,201.846z" />
      </g>
    </svg>
  );
};

export const PaidIcon = ({ className, width = "25px", height = "25px", ...props }) => {
  return (
    <svg
      className="MuiSvgIcon-root MuiSvgIcon-fontSizeMedium css-m9simb"
      focusable="false"
      aria-hidden="true"
      viewBox="0 0 24 24"
      data-testid="AttachMoneyOutlinedIcon"
      height={height}
      width={width}
      {...props}
    >
      <path d="M11.8 10.9c-2.27-.59-3-1.2-3-2.15 0-1.09 1.01-1.85 2.7-1.85 1.78 0 2.44.85 2.5 2.1h2.21c-.07-1.72-1.12-3.3-3.21-3.81V3h-3v2.16c-1.94.42-3.5 1.68-3.5 3.61 0 2.31 1.91 3.46 4.7 4.13 2.5.6 3 1.48 3 2.41 0 .69-.49 1.79-2.7 1.79-2.06 0-2.87-.92-2.98-2.1h-2.2c.12 2.19 1.76 3.42 3.68 3.83V21h3v-2.15c1.95-.37 3.5-1.5 3.5-3.55 0-2.84-2.43-3.81-4.7-4.4" />
    </svg>
  );
};

export const UnPaidIcon = ({ className, width = "25px", height = "25px", ...props }) => {
  return (
    <svg
      className="MuiSvgIcon-root MuiSvgIcon-fontSizeMedium css-m9simb"
      focusable="false"
      aria-hidden="true"
      viewBox="0 0 24 24"
      data-testid="MoneyOffOutlinedIcon"
      height={height}
      width={width}
      {...props}
    >
      <path d="M12.5 6.9c1.78 0 2.44.85 2.5 2.1h2.21c-.07-1.72-1.12-3.3-3.21-3.81V3h-3v2.16c-.39.08-.75.21-1.1.36l1.51 1.51c.32-.08.69-.13 1.09-.13M5.47 3.92 4.06 5.33 7.5 8.77c0 2.08 1.56 3.22 3.91 3.91l3.51 3.51c-.34.49-1.05.91-2.42.91-2.06 0-2.87-.92-2.98-2.1h-2.2c.12 2.19 1.76 3.42 3.68 3.83V21h3v-2.15c.96-.18 1.83-.55 2.46-1.12l2.22 2.22 1.41-1.41z" />
    </svg>
  );
};
