import React, { useEffect, useState } from "react";
import MyDialog from "../../components/MyDialog";
import {
  Box,
  Button,
  Card,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { Controller, useForm } from "react-hook-form";
import ErrorMSG from "../../components/ErrorMSG";
import LableText from "../../components/LableText";
import { getSoftwareList, updateSoftware } from "./redux/action";
import { toast } from "react-toastify";
import DropBox from "../../components/DropBox";
import { ModalIcon } from "../../components/Icons";
import { formatBytes } from "../../utils/custom";
import { CircularProgressBar } from "../../components/CircularProgressBar";
const initial = {
  software: "",
  currentVersion: "",
  updateVersion: "",
  uploadSoftware: ""
};
const UploadSoftware = ({ openDialog, handleClose, gridSpacing }) => {
  const dispatch = useDispatch();
  const { user, SoftwareList, isSoftwareModalLoading } = useSelector((state) => state.auth);
  const [IsFormLoading, setIsFormLoading] = useState(false);
  const [lodingTime, setLodingTime] = useState(0);
  const [files, setFiles] = useState([]);
  const {
    handleSubmit,
    register,
    control,
    setValue,
    reset,
    formState: { errors }
  } = useForm({
    defaultValues: initial
  });

  const CloseModal = () => {
    reset(initial);
    handleClose();
    setFiles([]);
  };

  const onSubmit = async (data) => {
    if (Object.keys(errors).length === 0) {
      setIsFormLoading(true);
      let formData = new FormData();
      formData.append("gameId", data.software);
      formData.append("version", data.updateVersion);
      formData.append("gameFile", data.uploadSoftware[0]);
      await dispatch(updateSoftware(formData, setLodingTime)).then(async (data) => {
        setIsFormLoading(false);
        if (data && data.payload) {
          if (data && data.payload.status) {
            dispatch(getSoftwareList());
            toast.success(data.payload.message);
            CloseModal();
          }
        }
      });
    }
  };
  useEffect(() => {
    user.userRole.includes("kiosk_software") &&
      openDialog &&
      dispatch(getSoftwareList()).then((res) => {
        if (res?.payload?.status) {
        } else {
        }
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, openDialog]);

  const Loader = isSoftwareModalLoading || IsFormLoading;
  return (
    <MyDialog
      maxWidth="sm"
      open={openDialog}
      handleClose={() => {
        !Loader && CloseModal();
      }}
      hidebackDrop
    >
      <form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
        <DialogTitle className="modal-header">Upload Software</DialogTitle>
        <DialogContent>
          <Box m="10px">
            <Grid container spacing={gridSpacing}>
              <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                <FormControl fullWidth size="small">
                  <InputLabel id="SelectSoftware">
                    <LableText>Select Software</LableText>
                  </InputLabel>
                  <Controller
                    render={({ field: { onChange, value } }) => (
                      <Select
                        fullWidth
                        size="small"
                        labelId="SelectSoftware"
                        label={<LableText>Select Software</LableText>}
                        className="z-index-1"
                        value={value !== undefined && value}
                        defaultValue={value !== undefined && value}
                        onChange={(e) => {
                          const version = SoftwareList.filter((data) => data._id === e.target.value);
                          onChange(e.target.value);
                          setValue("currentVersion", version[0]?.version);
                        }}
                        sx={{ textAlign: "left" }}
                      >
                        {SoftwareList?.length > 0 &&
                          SoftwareList.map((item, i) => {
                            return (
                              <MenuItem key={i} value={item?._id}>
                                {item?.gameName}
                              </MenuItem>
                            );
                          })}
                      </Select>
                    )}
                    control={control}
                    rules={{ required: true }}
                    name="software"
                  />
                  {errors.software && errors.software.type === "required" && <ErrorMSG text="Software is required" />}
                </FormControl>
              </Grid>
              <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                <FormControl fullWidth>
                  <Controller
                    render={({ field: { value } }) => (
                      <TextField
                        fullWidth
                        type={"text"}
                        size="small"
                        label={<p className="my-0">Current Version</p>}
                        defaultValue={value}
                        disabled
                        InputLabelProps={{ shrink: true }}
                        {...register("currentVersion")}
                      />
                    )}
                    control={control}
                    name="currentVersion"
                  />
                </FormControl>
              </Grid>
              <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                <FormControl fullWidth>
                  <Controller
                    render={({ field: { onChange, value } }) => (
                      <TextField
                        fullWidth
                        type={"text"}
                        size="small"
                        label={<p className="my-0">Update Version</p>}
                        value={value}
                        onChange={(e) => {
                          onChange(e.target.value);
                        }}
                        {...register("updateVersion")}
                      />
                    )}
                    control={control}
                    rules={{ required: true }}
                    name="updateVersion"
                  />
                  {errors.updateVersion && errors.updateVersion.type === "required" && (
                    <ErrorMSG text="Updated Version is required" />
                  )}
                </FormControl>
              </Grid>
              <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                <FormControl fullWidth>
                  <Controller
                    render={({ field: { onChange, value } }) => (
                      <DropBox
                        isfixSize={false}
                        required
                        acceptArry={["x-ms-dos-executable", "x-msdownload", "octet-stream"]}
                        accept=".exe"
                        onChange={(v) => {
                          onChange(v);
                          setFiles(v);
                        }}
                      />
                    )}
                    control={control}
                    rules={{ required: true }}
                    name="uploadSoftware"
                  />
                  {errors.uploadSoftware && errors.uploadSoftware.type === "required" && (
                    <ErrorMSG text="Software Upload is required" />
                  )}
                </FormControl>
              </Grid>
              <Grid
                item
                xl={12}
                lg={12}
                md={12}
                sm={12}
                xs={12}
                style={{
                  flex: "none"
                }}
              >
                {files.length > 0 &&
                  files.map((file, i) => {
                    return (
                      <Card
                        key={i}
                        className=""
                        sx={{
                          position: "relative",
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "left",
                          alignItems: "baseline",
                          padding: "10px"
                          // Width: "140px",
                          // height: "140px"
                        }}
                      >
                        {lodingTime > 0 && (
                          <div
                            style={{
                              position: "absolute",
                              top: "50%",
                              left: "50%",
                              zIndex: "1",
                              width: "100%",
                              height: "100%",
                              transform: "translate(-50%, -50%)",
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              background: "#040404b8"
                            }}
                          >
                            <CircularProgressBar value={lodingTime} />
                          </div>
                        )}
                        <ModalIcon width="50px" height="50px" />
                        <Box>
                          <Typography variant="h4" sx={{ fontSize: "12px" }}>
                            name: {file.name}
                          </Typography>
                          <Typography variant="h4" sx={{ fontSize: "12px" }}>
                            size: {formatBytes(file.size, 1)}
                          </Typography>
                        </Box>
                      </Card>
                    );
                  })}
              </Grid>
            </Grid>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button variant="outlined" disabled={Loader} onClick={CloseModal}>
            {Loader ? "Wait..." : "Close"}
          </Button>
          <Button type="submit" variant="contained" disabled={Loader}>
            {Loader ? "Wait..." : "Save"}
          </Button>
        </DialogActions>
      </form>
    </MyDialog>
  );
};

export default UploadSoftware;
