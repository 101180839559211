export const DistributorType = {
  GET_DISTRIBUTORS_REQUEST: "GET_DISTRIBUTORS_REQUEST",
  GET_DISTRIBUTORS_SUCCESS: "GET_DISTRIBUTORS_SUCCESS",
  GET_DISTRIBUTORS_FAIL: "GET_DISTRIBUTORS_FAIL",
  UPDATE_STATUS_REQUEST: "UPDATE_STATUS_REQUEST",
  UPDATE_STATUS_SUCCESS: "UPDATE_STATUS_SUCCESS",
  UPDATE_STATUS_FAIL: "UPDATE_STATUS_FAIL",
  CREATE_DISTRIBUTOR_REQUEST: "CREATE_DISTRIBUTOR_REQUEST",
  CREATE_DISTRIBUTOR_SUCCESS: "CREATE_DISTRIBUTOR_SUCCESS",
  CREATE_DISTRIBUTOR_FAIL: "CREATE_DISTRIBUTOR_FAIL",
  UPDATE_DISTRIBUTOR_REQUEST: "UPDATE_DISTRIBUTOR_REQUEST",
  UPDATE_DISTRIBUTOR_SUCCESS: "UPDATE_DISTRIBUTOR_SUCCESS",
  UPDATE_DISTRIBUTOR_FAIL: "UPDATE_DISTRIBUTOR_FAIL",
  GET_SINGLE_DISTRIBUTOR_REQUEST: "GET_SINGLE_DISTRIBUTOR_REQUEST",
  GET_SINGLE_DISTRIBUTOR_SUCCESS: "GET_SINGLE_DISTRIBUTOR_SUCCESS",
  GET_SINGLE_DISTRIBUTOR_FAIL: "GET_SINGLE_DISTRIBUTOR_FAIL",
  GET_DISTRIBUTOR_DROPDOWN_REQUEST: "GET_DISTRIBUTOR_DROPDOWN_REQUEST",
  GET_DISTRIBUTOR_DROPDOWN_SUCCESS: "GET_DISTRIBUTOR_DROPDOWN_SUCCESS",
  GET_DISTRIBUTOR_DROPDOWN_FAIL: "GET_DISTRIBUTOR_DROPDOWN_FAIL"
};
