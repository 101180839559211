import { Dialog, IconButton } from "@mui/material";
import React from "react";
import { CloseIcon } from "./Icons";
import { useTheme } from "@emotion/react";

export default function MyDialog({ maxWidth, open, handleClose, children, top, sx, hidebackDrop, ...rest }) {
  const { palette } = useTheme();

  return (
    <Dialog
      fullWidth
      maxWidth={maxWidth}
      disableEscapeKeyDown
      open={open}
      onClose={() => {
        if (hidebackDrop) {
          return;
        } else {
          handleClose();
        }
      }}
      sx={{
        overflowY: "auto",
        "& .MuiDialog-container": { height: "auto" },
        "& .MuiPaper-root": {
          overflowY: "unset",
          maxHeight: "unset !important",
          margin: "30px auto"
        },
        "& .MuiDialogContent-root": { overflowY: "unset" },
        "& .MuiDialogActions-root": {
          borderTop: 1,
          borderColor: `${palette.mode === "light" ? "rgba(81, 81, 81, 0.3)" : "rgba(255, 255, 255, 0.23)"}`,
          bottom: "0",
          padding: "16px 20px",
          borderRadius: "0 0 4px 4px"
        },
        ...sx
      }}
      {...rest}
    >
      <IconButton
        sx={{
          position: "absolute",
          top: top ? top : "5px",
          right: "5px",
          background: `${palette.mode === "light" ? palette.light.main : palette.dark.main} !important`
        }}
        onClick={() => {
          handleClose();
        }}
      >
        <CloseIcon />
      </IconButton>
      {children}
    </Dialog>
  );
}
