import { UserType } from "./type";

// Organizers intial state
const initialAuthState = {
  users: null,
  user: null,
  isLoading: false,
  statusCode: null,
  ServceStatus: false
};

export const reducer = (state = initialAuthState, action) => {
  switch (action.type) {
    case UserType.GET_USERS_REQUEST:
      return { ...state, isLoading: true };
    case UserType.GET_USERS_SUCCESS:
      return { ...state, isLoading: false, users: action.payload.data };
    case UserType.GET_USERS_FAIL:
      return { ...state, isLoading: false };
    case UserType.GET_USER_REQUEST:
      return { ...state, isLoading: true };
    case UserType.GET_USER_SUCCESS:
      return { ...state, isLoading: false, user: action.payload.data };
    case UserType.GET_USER_FAIL:
      return { ...state, isLoading: false };
    case UserType.USERS_ROLE_REQUEST:
      return { ...state, isLoading: true, statusCode: null };
    case UserType.USERS_ROLE_SUCCESS:
      return { ...state, isLoading: false };
    case UserType.USERS_ROLE_FAIL:
      return { ...state, isLoading: false, statusCode: action.statusCode };
    case UserType.USERS_ROLE_ASSIGN_REQUEST:
      return { ...state, isLoading: true };
    case UserType.USERS_ROLE_ASSIGN_SUCCESS:
      return { ...state, isLoading: false };
    case UserType.USERS_ROLE_ASSIGN_FAIL:
      return { ...state, isLoading: false };
    case UserType.KIOSK_STATUS_REQUEST:
      return { ...state, ServceStatus: true };
    case UserType.KIOSK_STATUS_SUCCESS:
      return { ...state, ServceStatus: false };
    case UserType.KIOSK_STATUS_FAIL:
      return { ...state, ServceStatus: false };
    default:
      return state;
  }
};
