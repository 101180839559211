export const KioskType = {
  GET_KIOSKS_REQUEST: "GET_KIOSKS_REQUEST",
  GET_KIOSKS_SUCCESS: "GET_KIOSKS_SUCCESS",
  GET_KIOSKS_FAIL: "GET_KIOSKS_FAIL",
  GET_KIOSK_REQUEST: "GET_KIOSK_REQUEST",
  GET_KIOSK_SUCCESS: "GET_KIOSK_SUCCESS",
  GET_KIOSK_FAIL: "GET_KIOSK_FAIL",
  GET_CREATE_KIOS_MODAL_REQUEST: "GET_CREATE_KIOS_MODAL_REQUEST",
  GET_CREATE_KIOS_MODAL_SUCCESS: "GET_CREATE_KIOS_MODAL_SUCCESS",
  GET_CREATE_KIOS_MODAL_FAIL: "GET_CREATE_KIOS_MODAL_FAIL",
  DELETE_KIOS_MODAL_REQUEST: "DELETE_KIOS_MODAL_REQUEST",
  DELETE_KIOS_MODAL_SUCCESS: "DELETE_KIOS_MODAL_SUCCESS",
  DELETE_KIOS_MODAL_FAIL: "DELETE_KIOS_MODAL_FAIL",
  UPDATE_KIOSK_BY_DESTRIBUTOR_REQUEST: "UPDATE_KIOSK_BY_DESTRIBUTOR_REQUEST",
  UPDATE_KIOSK_BY_DESTRIBUTOR_SUCCESS: "UPDATE_KIOSK_BY_DESTRIBUTOR_SUCCESS",
  UPDATE_KIOSK_BY_DESTRIBUTOR_FAIL: "UPDATE_KIOSK_BY_DESTRIBUTOR_FAIL",
  GENERATE_KIOSK_REQUEST: "GENERATE_KIOSK_REQUEST",
  GENERATE_KIOSK_SUCCESS: "GENERATE_KIOSK_SUCCESS",
  GENERATE_KIOSK_FAIL: "GENERATE_KIOSK_FAIL",
  COUNT_KIOSK_REQUEST: "COUNT_KIOSK_REQUEST",
  COUNT_KIOSK_SUCCESS: "COUNT_KIOSK_SUCCESS",
  COUNT_KIOSK_FAIL: "COUNT_KIOSK_FAIL"
};
