export const InvoiceType = {
  GET_INVOICES_REQUEST: "GET_INVOICES_REQUEST",
  GET_INVOICES_SUCCESS: "GET_INVOICES_SUCCESS",
  GET_INVOICES_FAIL: "GET_INVOICES_FAIL",
  GET_INVOICE_REQUEST: "GET_INVOICE_REQUEST",
  GET_INVOICE_SUCCESS: "GET_INVOICE_SUCCESS",
  GET_INVOICE_FAIL: "GET_INVOICE_FAIL",
  ADD_INVOICE_REQUEST: "ADD_INVOICE_REQUEST",
  ADD_INVOICE_SUCCESS: "ADD_INVOICE_SUCCESS",
  ADD_INVOICE_FAIL: "ADD_INVOICE_FAIL",
  GET_TAX_SUCCESS: "GET_TAX_SUCCESS",
  SET_INVOICE_REQUEST: "SET_INVOICE_REQUEST",
  SET_INVOICE_SUCCESS: "SET_INVOICE_SUCCESS",
  SET_INVOICE_FAIL: "SET_INVOICE_FAIL",
  ADD_ITEM_REQUEST: "ADD_ITEM_REQUEST",
  ADD_ITEM_SUCCESS: "ADD_ITEM_SUCCESS",
  ADD_ITEM_FAIL: "ADD_ITEM_FAIL",
  REMOVE_ITEM_REQUEST: "REMOVE_ITEM_REQUEST",
  REMOVE_ITEM_SUCCESS: "REMOVE_ITEM_SUCCESS",
  REMOVE_ITEM_FAIL: "REMOVE_ITEM_FAIL",
  ADD_RECIEPT_REQUEST: "ADD_RECIEPT_REQUEST",
  ADD_RECIEPT_SUCCESS: "ADD_RECIEPT_SUCCESS",
  ADD_RECIEPT_FAIL: "ADD_RECIEPT_FAIL",
  CONFIRM_RECIEPT_REQUEST: "CONFIRM_RECIEPT_REQUEST",
  CONFIRM_RECIEPT_SUCCESS: "CONFIRM_RECIEPT_SUCCESS",
  CONFIRM_RECIEPT_FAIL: "CONFIRM_RECIEPT_FAIL",
  SENDMAIL_REQUEST: "SENDMAIL_REQUEST",
  SENDMAIL_SUCCESS: "SENDMAIL_SUCCESS",
  SENDMAIL_FAIL: "SENDMAIL_FAIL",
  PDF_REQUEST: "PDF_REQUEST",
  PDF_SUCCESS: "PDF_SUCCESS",
  PDF_FAIL: "PDF_FAIL",
  ADD_KIOSKID_REQUEST: "ADD_KIOSKID_REQUEST",
  ADD_KIOSKID_SUCCESS: "ADD_KIOSKID_SUCCESS",
  ADD_KIOSKID_FAIL: "ADD_KIOSKID_FAIL",
  REMOVE_KIOSKID_REQUEST: "REMOVE_KIOSKID_REQUEST",
  REMOVE_KIOSKID_SUCCESS: "REMOVE_KIOSKID_SUCCESS",
  REMOVE_KIOSKID_FAIL: "REMOVE_KIOSKID_FAIL",
  KIOSKID_DROPDOWN_REQUEST: "KIOSKID_DROPDOWN_REQUEST",
  KIOSKID_DROPDOWN_SUCCESS: "KIOSKID_DROPDOWN_SUCCESS",
  KIOSKID_DROPDOWN_FAIL: "KIOSKID_DROPDOWN_FAIL",
  DELETE_INVOICE_REQUEST: "DELETE_INVOICE_REQUEST",
  DELETE_INVOICE_SUCCESS: "DELETE_INVOICE_SUCCESS",
  DELETE_INVOICE_FAIL: "DELETE_INVOICE_FAIL",
  INVOICE_COUNT_REQUEST: "INVOICE_COUNT_REQUEST",
  INVOICE_COUNT_SUCCESS: "INVOICE_COUNT_SUCCESS",
  INVOICE_COUNT_FAIL: "INVOICE_COUNT_FAIL",
  BILL_COUNT_REQUEST: "BILL_COUNT_REQUEST",
  BILL_COUNT_SUCCESS: "BILL_COUNT_SUCCESS",
  BILL_COUNT_FAIL: "BILL_COUNT_FAIL",
  SELF_ADMIN_LIST_REQUEST: "SELF_ADMIN_LIST_REQUEST",
  SELF_ADMIN_LIST_SUCCESS: "SELF_ADMIN_LIST_SUCCESS",
  SELF_ADMIN_LIST_FAIL: "SELF_ADMIN_LIST_FAIL",
  PDF_STATEMENT_REQUEST: "PDF_STATEMENT_REQUEST",
  PDF_STATEMENT_SUCCESS: "PDF_STATEMENT_SUCCESS",
  PDF_STATEMENT_FAIL: "PDF_STATEMENT_FAIL"
};
