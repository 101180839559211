export const AdvertiseType = {
  GET_ADVERTISES_REQUEST: "GET_ADVERTISES_REQUEST",
  GET_ADVERTISES_SUCCESS: "GET_ADVERTISES_SUCCESS",
  GET_ADVERTISES_FAIL: "GET_ADVERTISES_FAIL",
  GET_ADVERTISE_REQUEST: "GET_ADVERTISE_REQUEST",
  GET_ADVERTISE_SUCCESS: "GET_ADVERTISE_SUCCESS",
  GET_ADVERTISE_FAIL: "GET_ADVERTISE_FAIL",
  UPDATE_STATUS_REQUEST: "UPDATE_STATUS_REQUEST",
  UPDATE_STATUS_SUCCESS: "UPDATE_STATUS_SUCCESS",
  UPDATE_STATUS_FAIL: "UPDATE_STATUS_FAIL",
  CREATE_ADVERTISE_REQUEST: "CREATE_ADVERTISE_REQUEST",
  CREATE_ADVERTISE_SUCCESS: "CREATE_ADVERTISE_SUCCESS",
  CREATE_ADVERTISE_FAIL: "CREATE_ADVERTISE_FAIL",
  UPDATE_ADVERTISE_REQUEST: "UPDATE_ADVERTISE_REQUEST",
  UPDATE_ADVERTISE_SUCCESS: "UPDATE_ADVERTISE_SUCCESS",
  UPDATE_ADVERTISE_FAIL: "UPDATE_ADVERTISE_FAIL",
  DELETE_ADVERTISE_REQUEST: "DELETE_ADVERTISE_REQUEST",
  DELETE_ADVERTISE_SUCCESS: "DELETE_ADVERTISE_SUCCESS",
  DELETE_ADVERTISE_FAIL: "DELETE_ADVERTISE_FAIL"
};
