import { API_ROOT, API_ROOT_LOCATION } from "./config";
import axios from "axios";
import UseEncryption from "./UseEncryption";
import store from "../redux/store";

// export default JsonInterceptor;
const customAxios = (contentType, location = false) => {
  const axiosInstance = axios.create({
    baseURL: !location ? API_ROOT : API_ROOT_LOCATION
  });
  axiosInstance.interceptors.request.use(
    async (config) => {
      const adminInfo = JSON.parse(localStorage.getItem("spinzyPlayAdminUSA"));
      if (adminInfo?.adminAuthorization) {
        config.headers.adminAuthorization = adminInfo?.adminAuthorization;
      }
      if (adminInfo?.operatorAuthorization) {
        config.headers.operatorAuthorization = adminInfo?.operatorAuthorization;
      }
      config.headers.platform = "admin";

      config.headers["Content-Type"] = contentType ? contentType : "application/json";

      return config;
    },
    (error) => {
      if (error.response && error.response.status === 401) {
        store.dispatch({ type: "LOG_OUT" });
      }
      if (error.response && error.response) {
        return Promise.reject(error.response);
      }
      return Promise.reject(error);
    }
  );

  axiosInstance.interceptors.response.use(
    async (response) => {
      // console.log(process.env.REACT_APP_ENV === "production" && UseEncryption(response.data), `<<=====response`);
      return process.env.REACT_APP_ENV === "production"
        ? { ...response, data: UseEncryption(response.data) }
        : response;
    },
    (error) => {
      if (error.response && error.response) {
        if (error.response && error.response.status === 401) {
          store.dispatch({ type: "LOG_OUT" });
        }
        // console.error(process.env.REACT_APP_ENV === "production" && UseEncryption(error.response.data), `<<=====error`);
        return Promise.reject(
          process.env.REACT_APP_ENV === "production"
            ? { ...error.response, data: UseEncryption(error.response.data) }
            : error.response
        );
      }
    }
  );
  return axiosInstance;
};

const JsonInterceptor = customAxios("application/json");
export const JsonLocationAPI = customAxios("application/json", true);
export const axiosForMultipart = customAxios("multipart/form-data");
export const axiosForMultipartLocationAPI = function name(param) {
  return customAxios("multipart/form-data", true);
};

export default JsonInterceptor;
