import { Box, IconButton, useTheme, Typography, Divider, Grid, useMediaQuery, Button } from "@mui/material";
import { ColorModeContext } from "../../theme";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { useContext, useState } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { logout } from "../login/redux/action";
import ChangePassword from "../login/ChangePassword";
import LogoutDialog from "../login/LogoutDialog";
import { useNavigate } from "react-router-dom";
import {
  ChangePasswordIcon,
  DarkModeIcon,
  LightModeIcon,
  LogoutIcon,
  MenuIcon,
  // ModalIcon,
  UserFillIcon
} from "../../components/Icons";
import UploadSoftware from "../login/UploadSoftware";
// import UserAccess from "../../utils/UserAccess";
import { userTypeLable } from "../../utils/custom";
import { SUPERACCESS } from "../../utils/custom";

const Topbar = ({ handleToggleSidebar }) => {
  const { palette, breakpoints } = useTheme();
  const isSmall = useMediaQuery(breakpoints.down("sm") || breakpoints.down("xs"));
  const gridSpacing = isSmall ? 2 : 3;
  const navigate = useNavigate();
  const [openLogout, setOpenLogout] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [softwareUpdate, setSoftwareUpdate] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.auth, shallowEqual);
  // const SOFTWARE_PERMISSION = UserAccess("kiosk_software");
  const handleClickOpen = () => {
    setOpenDialog(true);
  };
  const handleClickLogoutOpen = () => {
    setOpenLogout(true);
  };
  const handleLogoutClose = () => {
    setOpenLogout(false);
  };
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleCloseMenu = () => {
    setAnchorEl(null);
  };
  const handleCloseDialog = () => {
    setOpenDialog(false);
  };
  const onHandleLogout = async () => {
    await dispatch(logout());
  };

  const colorMode = useContext(ColorModeContext);

  return (
    <>
      <Box
        p={2}
        className="topBar"
        sx={{
          boxShadow: `0 0 3px ${palette.mode === "dark" ? "palette.secondary.main" : palette.grey[400]}`,
          background: `${palette.mode === "light" ? palette.light.main : palette.dark.main} !important`
        }}
      >
        <Grid container spacing={gridSpacing} flexWrap="wrap-reverse" alignItems="center">
          <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
            <Box
              display="flex"
              sx={{ alignItems: "center", justifyContent: "flex-end", gap: "8px" }}
              className="responsive-head"
            >
              <IconButton className="responsiveMenuIcon" onClick={() => handleToggleSidebar(true)}>
                <MenuIcon />
              </IconButton>
              <Box
                sx={{
                  display: "flex",
                  width: "100%",
                  justifyContent: "space-between",
                  alignItems: "center",
                  gap: "4px"
                }}
              >
                <Box>
                  {!SUPERACCESS.includes(user?.userType) && user?.businessName && (
                    <Typography
                      variant="span"
                      sx={{
                        fontSize: "20px",
                        fontWeight: 700,
                        letterSpacing: 1,
                        textTransform: "capitalize",
                        color: `${palette.mode === "light" ? palette.dark.main : palette.light.main} !important`
                      }}
                    >
                      {user.businessName}
                    </Typography>
                  )}
                </Box>
                <Box>
                  <IconButton onClick={colorMode.toggleColorMode}>
                    {palette.mode === "dark" ? <LightModeIcon /> : <DarkModeIcon />}
                  </IconButton>
                  <Button
                    onClick={handleClick}
                    size="small"
                    sx={{
                      color: `${palette.mode === "light" ? palette.grey.main : palette.grey.light} !important`
                    }}
                  >
                    <UserFillIcon width={30} height={30} />
                    <Typography
                      variant="h6"
                      sx={{ textAlign: "left", lineHeight: "18px" }}
                      className="hideInResponsive logtext"
                      ml="4px"
                    >
                      <Typography component={"span"} sx={{ fontSize: "12px", lineHeight: "11px" }}>
                        {userTypeLable(user?.userType)}
                        <br />
                      </Typography>
                      {user?.firstName} {user?.lastName}
                    </Typography>
                  </Button>
                </Box>
              </Box>
              <Menu
                className="userMenu"
                id="basic-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleCloseMenu}
                MenuListProps={{
                  "aria-labelledby": "basic-button"
                }}
              >
                <Box sx={{ padding: "6px 16px" }}>
                  <Typography
                    variant="h4"
                    color={palette.primary.main}
                    sx={{
                      fontWeight: "bold",
                      wordBreak: "break-word",
                      whiteSpace: "normal",
                      overflow: "auto"
                    }}
                  >
                    {user?.firstName} {user?.lastName}
                  </Typography>
                  <Typography
                    sx={{
                      wordBreak: "break-word",
                      whiteSpace: "normal",
                      overflow: "auto"
                    }}
                  >
                    {user?.email}
                  </Typography>
                </Box>
                <Divider sx={{ my: 1 }} />
                <MenuItem
                  sx={{ gap: "10px" }}
                  onClick={() => {
                    navigate("/profile");
                    handleCloseMenu();
                  }}
                >
                  <UserFillIcon />
                  User Profile
                </MenuItem>
                {/* {SOFTWARE_PERMISSION && (
                  <MenuItem
                    sx={{ gap: "10px" }}
                    onClick={() => {
                      setSoftwareUpdate(true);
                      handleCloseMenu();
                    }}
                  >
                    <ModalIcon />
                    Upload Software
                  </MenuItem>
                )} */}
                <MenuItem
                  sx={{ gap: "10px" }}
                  onClick={() => {
                    handleClickOpen();
                    handleCloseMenu();
                  }}
                >
                  <ChangePasswordIcon />
                  Change Password
                </MenuItem>

                <MenuItem sx={{ gap: "10px" }} onClick={() => setOpenLogout(true)}>
                  <LogoutIcon />
                  Logout
                </MenuItem>
              </Menu>
            </Box>
          </Grid>
        </Grid>
      </Box>
      <ChangePassword openDialog={openDialog} handleClose={handleCloseDialog} onClick={handleClickOpen} />
      <UploadSoftware
        openDialog={softwareUpdate}
        handleClose={() => setSoftwareUpdate(false)}
        onClick={handleClickOpen}
        gridSpacing={gridSpacing}
      />
      <LogoutDialog
        openLogout={openLogout}
        handleClickLogoutOpen={handleClickLogoutOpen}
        handleLogoutClose={handleLogoutClose}
        onHandleLogout={onHandleLogout}
      />
    </>
  );
};

export default Topbar;
