import { Suspense, lazy, useState } from "react";
import { Box } from "@mui/system";
import { Routes, Route, Navigate } from "react-router-dom";
import Topbar from "./Topbar";
import GlobalLoader from "../../components/GlobalLoader";
import SidebarComponent from "./Sidebar";
import DecryptComponent from "../../components/DecryptComponent";
import { useSelector } from "react-redux";
import UserAccess from "../../utils/UserAccess";

const BillStatements = lazy(() => import("../invoices/BillStatements"));
const ProfileForm = lazy(() => import("../login/ProfileForm"));
const Profile = lazy(() => import("../login/Profile"));
const Invoices = lazy(() => import("../invoices"));
const InvoiceOverview = lazy(() => import("../invoices/InvoiceOverview"));
const BillOverview = lazy(() => import("../invoices/BillOverview"));
const BillTable = lazy(() => import("../invoices/BillTable"));
const InvoiceForm = lazy(() => import("../invoices/InvoiceForm"));
const ReturnInvoiceForm = lazy(() => import("../invoices/ReturnInvoiceForm"));
const InvoiceViw = lazy(() => import("../invoices/InvoiceViw"));
const Distributors = lazy(() => import("../distributors"));
const DistributorForm = lazy(() => import("../distributors/DistributorForm"));
const DistributorDashboard = lazy(() => import("../distributors/DistributorDashboard"));
const Users = lazy(() => import("../users"));
const UserRole = lazy(() => import("../users/UserRole"));
const Operators = lazy(() => import("../operators"));
const OperatorForm = lazy(() => import("../operators/OperatorForm"));
const OperatorDashboard = lazy(() => import("../operators/OperatorDashboard"));
const Dashboard = lazy(() => import("../dashboard"));
const Advertises = lazy(() => import("../advertises"));
const Locations = lazy(() => import("../locations"));
const LocationForm = lazy(() => import("../locations/LocationForm"));
const LocationDashboard = lazy(() => import("../locations/LocationDashboard"));
const LocationDashboardListing = lazy(() => import("../locations/LocationDashboardListing"));
const Customers = lazy(() => import("../customers"));
const Transactions = lazy(() => import("../transactions"));
const Kiosk = lazy(() => import("../kiosks"));
const KioskModalList = lazy(() => import("../kiosks/KioskModalList"));
// const GenerateKiosk = lazy(() => import("../kiosks/GenerateKiosk"));
const Statement = lazy(() => import("../statement"));
const Settings = lazy(() => import("../settings"));

export default function Layout() {
  const [toggled, setToggled] = useState(false);
  const { user } = useSelector((state) => state.auth);

  const handleToggleSidebar = (value) => {
    setToggled(value);
  };

  return (
    <Box sx={{ display: "flex", flexGrow: 1, width: "100%", height: "100%" }}>
      <Box className={`${toggled ? "toggled" : ""}`}>
        <SidebarComponent toggled={toggled} handleToggleSidebar={handleToggleSidebar} />
      </Box>
      <Box
        sx={{ minWidth: 0, flex: "0 1 auto", overflowY: "auto" }}
        width="100%"
        height="100%"
        className="respBottomSpace"
      >
        <Topbar handleToggleSidebar={handleToggleSidebar} />
        <Suspense fallback={<GlobalLoader />}>
          <Routes>
            <Route exact path="/" element={<Dashboard />} />
            {UserAccess("invoice_list") && <Route path="/invoices-list" element={<Invoices />} />}
            {UserAccess("invoice_list") && <Route exact path="/invoices-overview" element={<InvoiceOverview />} />}
            {UserAccess("view_bill") && <Route path="/payable-list" element={<BillTable />} />}
            {UserAccess("view_bill") && <Route path="/payable-statements" element={<BillStatements />} />}
            {UserAccess("view_bill") && <Route exact path="/payable-overview" element={<BillOverview />} />}
            {UserAccess("invoice_create") && <Route exact path="/invoices/add" element={<InvoiceForm />} />}
            {UserAccess("invoice_create") && <Route exact path="/invoices/return" element={<ReturnInvoiceForm />} />}
            {UserAccess("invoice_view") && <Route exact path="/invoices/:id" element={<InvoiceViw />} />}

            {UserAccess("distributor_list") && <Route path="/distributors" element={<Distributors />} />}
            {UserAccess("distributor_add") && <Route exact path="/distributors/add" element={<DistributorForm />} />}
            {UserAccess("distributor_edit") && (
              <Route exact path="/distributors/edit/:id" element={<DistributorForm />} />
            )}
            {UserAccess("distributor_view") && (
              <Route exact path="/distributors/:id" element={<DistributorDashboard />} />
            )}
            {UserAccess("user_list") && <Route exact path="/users" element={<Users />} />}
            {(user?.userType === "superAdmin" || user?.userType === "admin" || user?.userType === "operator") && (
              <Route path="/users/role/:id" element={<UserRole />} />
            )}
            {UserAccess("operator_list") && <Route exact path="/operators" element={<Operators />} />}
            {UserAccess("operator_add") && <Route exact path="/operators/add" element={<OperatorForm />} />}
            {UserAccess("operator_edit") && <Route exact path="/operators/edit/:id" element={<OperatorForm />} />}
            {UserAccess("operator_view") && <Route exact path="/operators/:id" element={<OperatorDashboard />} />}
            {UserAccess("location_list") && <Route exact path="/locations" element={<Locations />} />}
            {UserAccess("location_add") && <Route exact path="/locations/add" element={<LocationForm />} />}
            {UserAccess("location_edit") && <Route exact path="/locations/edit/:id" element={<LocationForm />} />}
            {UserAccess("location_view") && <Route exact path="/locations/:id" element={<LocationDashboard />} />}
            {UserAccess("location_view") && (
              <Route exact path="/locations/:id/listing" element={<LocationDashboardListing />} />
            )}
            {UserAccess("customer_list") && <Route exact path="/customers" element={<Customers />} />}
            {UserAccess("transaction_list") && <Route exact path="/transactions" element={<Transactions />} />}
            {UserAccess("advertisement_list") && <Route exact path="/advertises" element={<Advertises />} />}
            {UserAccess("kiosk_model_list_tId") && <Route exact path="/kiosks" element={<Kiosk />} />}
            {UserAccess("kiosk_model_list") && <Route exact path="/kiosks/modal" element={<KioskModalList />} />}
            {user?.userType === "superAdmin" && <Route exact path="/settings" element={<Settings />} />}
            {/* {UserAccess("kiosk_model_generate_tId") && (
              <Route exact path="/kiosks/generate" element={<GenerateKiosk />} />
            )} */}
            {UserAccess("statement_list") && <Route exact path="/statements" element={<Statement />} />}
            <Route exact path="/profile" element={user?.userType === "superAdmin" ? <ProfileForm /> : <Profile />} />
            <Route exact path="/decrypt" element={<DecryptComponent />} />
            <Route path="*" element={<Navigate to="/" replace />} />
          </Routes>
        </Suspense>
      </Box>
    </Box>
  );
}
