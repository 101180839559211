export const AuthType = {
  // login module types
  LOG_OUT: "LOG_OUT",
  USER_SIGNIN_REQUEST: "USER_SIGNIN_REQUEST",
  USER_SIGNIN_SUCCESS: "USER_SIGNIN_SUCCESS",
  USER_OTP_SUCCESS: "USER_OTP_SUCCESS",
  USER_SIGNIN_FAIL: "USER_SIGNIN_FAIL",
  AUTH_CHEK_REQUEST: "AUTH_CHEK_REQUEST",
  AUTH_CHEK_SUCCESS: "AUTH_CHEK_SUCCESS",
  AUTH_CHEK_FAIL: "AUTH_CHEK_FAIL",
  REFRESH_CAPTCHA_REQUEST: "REFRESH_CAPTCHA_REQUEST",
  REFRESH_CAPTCHA_SUCCESS: "REFRESH_CAPTCHA_SUCCESS",
  REFRESH_CAPTCHA_FAIL: "REFRESH_CAPTCHA_FAIL",
  CHEK_CAPTCHA_REQUEST: "CHEK_CAPTCHA_REQUEST",
  CHEK_CAPTCHA_SUCCESS: "CHEK_CAPTCHA_SUCCESS",
  CHEK_CAPTCHA_FAIL: "CHEK_CAPTCHA_FAIL",
  PASSWORD_CHANGE_REQUEST: "PASSWORD_CHANGE_REQUEST",
  PASSWORD_CHANGE_SUCCESS: "PASSWORD_CHANGE_SUCCESS",
  PASSWORD_CHANGE_FAIL: "PASSWORD_CHANGE_FAIL",

  //Profile Module
  GET_PROFILE_REQUEST: "GET_PROFILE_REQUEST",
  GET_PROFILE_SUCCESS: "GET_PROFILE_SUCCESS",
  GET_PROFILE_FAIL: "GET_PROFILE_FAIL",
  UPDATE_PROFILE_REQUEST: "UPDATE_PROFILE_REQUEST",
  UPDATE_PROFILE_SUCCESS: "UPDATE_PROFILE_SUCCESS",
  UPDATE_PROFILE_FAIL: "UPDATE_PROFILE_FAIL",
  RESEND_VERIFY_EMAIL_REQUEST: "RESEND_VERIFY_EMAIL_REQUEST",
  RESEND_VERIFY_EMAIL_SUCCESS: "RESEND_VERIFY_EMAIL_SUCCESS",
  RESEND_VERIFY_EMAIL_FAIL: "RESEND_VERIFY_EMAIL_FAIL",
  VERIFY_EMAIL_REQUEST: "VERIFY_EMAIL_REQUEST",
  VERIFY_EMAIL_SUCCESS: "VERIFY_EMAIL_SUCCESS",
  VERIFY_EMAIL_FAIL: "VERIFY_EMAIL_FAIL",

  RESET_PASSWORD_REQUEST: "RESET_PASSWORD_REQUEST",
  RESET_PASSWORD_SUCCESS: "RESET_PASSWORD_SUCCESS",
  RESET_PASSWORD_FAIL: "RESET_PASSWORD_FAIL",

  // get contrey
  GET_COUNTRY: "GET_COUNTRY",

  GET_ADDRESS_REQUEST: "GET_ADDRESS_REQUEST",
  GET_ADDRESS_SUCCESS: "GET_ADDRESS_SUCCESS",
  GET_ADDRESS_FAIL: "GET_ADDRESS_FAIL",

  //get software kist
  GET_SOFTWARE_LIST_REQUEST: "GET_SOFTWARE_LIST_REQUEST",
  GET_SOFTWARE_LIST_SUCCESS: "GET_SOFTWARE_LIST_SUCCESS",
  GET_SOFTWARE_LIST_FAIL: "GET_SOFTWARE_LIST_FAIL",
  GET_APPLICATION_VERSION_LIST_REQUEST: "GET_APPLICATION_VERSION_LIST_REQUEST",
  GET_APPLICATION_VERSION_LIST_SUCCESS: "GET_APPLICATION_VERSION_LIST_SUCCESS",
  GET_APPLICATION_VERSION_LIST_FAIL: "GET_APPLICATION_VERSION_LIST_FAIL",
  GET_SOFTWARE_UPDATE_REQUEST: "GET_SOFTWARE_UPDATE_REQUEST",
  GET_SOFTWARE_UPDATE_SUCCESS: "GET_SOFTWARE_UPDATE_SUCCESS",
  GET_SOFTWARE_UPDATE_FAIL: "GET_SOFTWARE_UPDATE_FAIL"
};
