import { DashboardType } from "./type";

const initialAuthState = {
  userCount: null,
  isUserCountLoading: false,
  UserCountError: null,
  ratioCount: null,
  isratioCountLoading: false,
  ratioCountError: null,
  moneyInOutCount: null,
  isMoneyInOutCountLoading: false,
  moneyInOutCountError: null,
  customerCount: null,
  isCustomerCountLoading: false,
  customerCountError: null,
  commisionCount: null,
  isCommisionCountLoading: false,
  isCommisionCountError: null,
  paymentCount: null,
  isPaymentCountLoading: false,
  chart: null,
  isChartLoading: false,
  chartError: null,
  profitList: [],
  isProfitListLoading: false
};

export const reducer = (state = initialAuthState, action) => {
  switch (action.type) {
    case DashboardType.GET_USER_COUNT_REQUEST:
      return { ...state, isUserCountLoading: true };
    case DashboardType.GET_USER_COUNT_SUCCESS:
      return { ...state, isUserCountLoading: false, userCount: action.payload };
    case DashboardType.GET_USER_COUNT_FAIL:
      return { ...state, isUserCountLoading: false, UserCountError: action.error };
    case DashboardType.GET_WINNING_RATIO_COUNT_REQUEST:
      return { ...state, isratioCountLoading: true };
    case DashboardType.GET_WINNING_RATIO_COUNT_SUCCESS:
      return { ...state, isratioCountLoading: false, ratioCount: action.payload };
    case DashboardType.GET_WINNING_RATIO_COUNT_FAIL:
      return { ...state, isratioCountLoading: false, ratioCountError: action.error };
    case DashboardType.GET_MONEY_IN_OUT_COUNT_REQUEST:
      return { ...state, isMoneyInOutCountLoading: true };
    case DashboardType.GET_MONEY_IN_OUT_COUNT_SUCCESS:
      return { ...state, isMoneyInOutCountLoading: false, moneyInOutCount: action.payload };
    case DashboardType.GET_MONEY_IN_OUT_COUNT_FAIL:
      return { ...state, isMoneyInOutCountLoading: false, moneyInOutCountError: action.error };
    case DashboardType.GET_CUSTOMER_COUNT_REQUEST:
      return { ...state, isCustomerCountLoading: true };
    case DashboardType.GET_CUSTOMER_COUNT_SUCCESS:
      return { ...state, isCustomerCountLoading: false, customerCount: action.payload };
    case DashboardType.GET_CUSTOMER_COUNT_FAIL:
      return { ...state, isCustomerCountLoading: false, customerCountError: action.error };
    case DashboardType.GET_COMMISION_COUNT_REQUEST:
      return { ...state, isCommisionCountLoading: true };
    case DashboardType.GET_COMMISION_COUNT_SUCCESS:
      return { ...state, isCommisionCountLoading: false, commisionCount: action.payload };
    case DashboardType.GET_COMMISION_COUNT_FAIL:
      return { ...state, isCommisionCountLoading: false, commisionCountError: action.error };
    case DashboardType.GET_PAYMENT_COUNT_REQUEST:
      return { ...state, isPaymentCountLoading: true };
    case DashboardType.GET_PAYMENT_COUNT_SUCCESS:
      return { ...state, isPaymentCountLoading: false, paymentCount: action.payload };
    case DashboardType.GET_PAYMENT_COUNT_FAIL:
      return { ...state, isPaymentCountLoading: false, paymentCountError: action.error };
    case DashboardType.GET_DASHBOARD_CHART_REQUEST:
      return { ...state, isChartLoading: true };
    case DashboardType.GET_DASHBOARD_CHART_SUCCESS:
      return { ...state, isChartLoading: false, chart: action.payload.chartData };
    case DashboardType.GET_DASHBOARD_CHART_FAIL:
      return { ...state, isChartLoading: false, chartError: action.error };
    case DashboardType.GET_DASHBOARD_PROFITLIST_REQUEST:
      return { ...state, isProfitListLoading: true };
    case DashboardType.GET_DASHBOARD_PROFITLIST_SUCCESS:
      return { ...state, isProfitListLoading: false, profitList: action.payload };
    case DashboardType.GET_DASHBOARD_PROFITLIST_FAIL:
      return { ...state, isProfitListLoading: false };
    default:
      return state;
  }
};
