import { SettingType } from "./types";

// Organizers intial state
const initialAuthState = {
  status: null,
  isLoading: false,
  error: null,
  isRatioLoading: false
};

export const reducer = (state = initialAuthState, action) => {
  switch (action.type) {
    case SettingType.SERVICE_MAINTENANCE_REQUEST:
      return { ...state, isLoading: true, error: null };
    case SettingType.SERVICE_MAINTENANCE_SUCCESS:
      return { ...state, isLoading: false, status: action.payload.data, error: null };
    case SettingType.SERVICE_MAINTENANCE_FAIL:
      return { ...state, isLoading: false, error: action.error };
    case SettingType.CHANGE_WINNING_RATIO_REQUEST:
      return { ...state, isRatioLoading: true, error: null };
    case SettingType.CHANGE_WINNING_RATIO_SUCCESS:
      return { ...state, isRatioLoading: false, success: action.payload.data, error: null };
    case SettingType.CHANGE_WINNING_RATIO_FAIL:
      return { ...state, isRatioLoading: false, error: action.error };
    default:
      return state;
  }
};
